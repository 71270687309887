/*Main styles*/
#map_container img {
	border: 0;
}

#map_container {
	position: relative;
	margin: 20px auto;
	width: 421px;
	height: 391px;
	z-index: 2;
	overflow: visible;
	@media (max-width:979px) {
		zoom: 0.7;
	}
}

#map_container #map_img {
	padding: 0;
	margin: 0;
	border: 0;
	width: 421px;
	height: 391px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
}

#map_container #map_header {
	margin: 5px 0 0 -10px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 4;
}

#map_container #map_header h3 {
	padding: 0;
}

/*City link styles*/

#map_container a,
#map_container a:link,
#map_container a:visited,
#map_container a:hover,
#map_container a:active {
	position: absolute;
	z-index: 5;
	display: block;
	color: #000;
	font-size: 11px;
	font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif;
	background: transparent url('/assets/i/point.png') 0 0 no-repeat;
	padding: 0 0 0 20px;
	margin: 0;
	text-decoration: none;
	height: 16px;
	line-height: 14px;
	text-shadow: 1px 1px 2px #fff;
	}

#map_container a:hover {
	color: #fff;
	background-position: 0 100%;
	}

#map_container a.rightbg {
	background-position: 100% 0;
	padding: 0 20px 0 0;
	}

#map_container a.rightbg:hover {
	background-position: 100% 100%;
	}

#map_container a.hilight {
	color: #fff;
	background-position: 0 100%;
	}
#map_container a.hilight.rightbg {
	background-position: 100% 100%;
	}


#map_container #shigoni {
	top: 153px;
	left: 14px;
	}

#map_container #ilmen {
	top: 200px;
	left: 58px;
	}

#map_container #privolzhe {
	top: 225px;
	left: 53px;
	}

#map_container #hvorostianka {
	top: 262px;
	left: 85px;
	}

#map_container #pestravka {
	top: 300px;
	left: 174px;
	}

#map_container #samara {
	top: 181px;
	left: 195px;
	font-size: 12px;
	background: transparent url('/assets/i/pointbig.png') 0 0 no-repeat;
	height: 20px;
	line-height: 20px;
	}

#map_container #samara:hover {
	background-position: 0 100%;
	}

#map_container #novok {
	top: 207px;
	left: 170px;
	}

#map_container #chapaevsk {
	top: 225px;
	left: 145px;
	}

#map_container #bezenchuk {
	top: 212px;
	left: 105px;
	}


#map_container #pohvistnevo {
	top: 136px;
	left: 297px;
	}

/*Popup styles*/

#map_container #map_popup {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 6;
	display: none;
	width: 310px; /*Hack for Safari & Chrome =*/
	}

#map_container #map_popup img {
	border: 0 none;
	background: none;
	margin: 0;
	padding: 0;
	}

#map_container #map_popup td.top-left {
	background: url('/assets/i/map_popup/top-left.png') 100% 100% no-repeat;
}
#map_container #map_popup td.top {
	background: url('/assets/i/map_popup/top-middle.png') 100% 100% repeat-x;
	width: auto;
}
#map_container #map_popup td.top-right {
	background: url('/assets/i/map_popup/top-right.png') 0 100% no-repeat;
}
#map_container #map_popup td.left {
	background: url('/assets/i/map_popup/middle-left.png') 100% 100% repeat-y;
	height: auto;
	vertical-align: bottom;
	float: none;
	margin: 0;
    font-size: 0px;
    line-height: 0px;
}
#map_container #map_popup td.middle {
	background: #fff url('/assets/i/map_popup/img-middle.png') 100% 100% repeat-x;
	height: auto;
	width: auto;
}
#map_container #map_popup td.right {
	background: url('/assets/i/map_popup/middle-right.png') 0 100% repeat-y;
	height: auto;
	vertical-align: bottom;
	float: none;
	margin: 0;
    font-size: 0px;
    line-height: 0px;
}
#map_container #map_popup td.bottom-left {
	background: url('/assets/i/map_popup/bottom-left.png') 100% 0 no-repeat;
}
#map_container #map_popup td.bottom {
	background: url('/assets/i/map_popup/bottom-middle.png') 100% 0 repeat-x;
	width: auto;
	overflow: visible;
}
#map_container #map_popup td.bottom-right {
	background: url('/assets/i/map_popup/bottom-right.png') 0 0 no-repeat;
}
#map_container #map_popup td.param {
	width: 10px;
	height: 10px;
}

/*Info styles*/

#map_container #map_info {
	display: none;
	}

#map_container #map_info div {
	display: none;
	}

#map_container #map_popup div.info {
	border-bottom: dashed 1px #ccc;
	font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif;
	margin: 5px 0;
	padding: 0 5px 20px 5px;
	min-height: 45px;
	}

#map_container #map_popup div.left {
	float: left;
	/*max-width: 150px;*/
	width: 150px;
	padding: 0 5px;
	margin: 0;
	}

#map_container #map_popup div.right {
	float: right;
	/*max-width: 110px;*/
	width: 110px;
	padding: 0 5px;
	margin: 0;
	}

#map_container #map_popup h5 {
	color: #0f9fda;
	font-size: 11px;
	padding: 0;
	margin: 0;
	background: none;
	}

#map_container #map_popup div.left p {
	font-size: 12px;
	}

#map_container #map_popup div.left p strong {
	font-size: 13px;
	}

#map_container #map_popup div.right p {
	font-size: 10px;
	}

#map_container #map_popup div.info a,
#map_container #map_popup div.info a:link,
#map_container #map_popup div.info a:visited,
#map_container #map_popup div.info a:hover,
#map_container #map_popup div.info a:active {
	display: block;
	width: 260px;
	background: none;
	color: #0081b9;
	text-decoration: underline;
	text-align: center;
	}

#map_container #map_popup div.info a:hover {
	text-decoration: none;
	}

/*Tip*/

#map_container #tip {
	width: 220px;
	font-size: 9px;
	color: #aeaeae;
    float: left;
	}
#map_container #tip:before {
	content: "*";
	margin: 0 0 0 -8px;
	padding: 0 2px 0 0;
}

.mapblock-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 22px;
	@media (max-width:979px) {
		margin-bottom: 40px;
	}
	@media (max-width:767px) {
		flex-direction: column;
	}
	h2 {
		margin-top: 0 !important;
	}
	h3 {
		margin-bottom: 26px !important;
		@media (max-width:767px) {
			margin-bottom: 18px !important;
			font-size: 22px !important;
			line-height: 1.1 !important;
		}
	}
	.banner {
		margin-top: 0;
		img {
			padding: 0 !important;
			border: none !important;
		}
	}
}

.map-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	@media (max-width:979px) {
		align-items: unset;
	}
	@media (max-width:767px) {
		flex-direction: column;
		align-items: center;
	}
	#map_container {
		margin: 0;
	}
	.map-info {
		display: flex;
		flex-direction: column;
		margin-left: 35px;
		@media (max-width:979px) {
			margin-left: 15px;
		}
		@media (max-width:767px) {
			margin-left: 0;
		}
	}
	.map-info-item {
		&:not(:last-child) {
			margin-bottom: 18px;
			@media (max-width:979px) {
				margin-bottom: 9px;
			}
		}
	}
	.map-info-value {
		font-size: 35px !important;
		font-weight: bold !important;
		color: #0E96D2 !important;
		@media (max-width:979px) {
			font-size: 26px !important;
			line-height: 24px;
		}
		@media (max-width:767px) {
			margin-bottom: 8px;
			font-size: 21px !important;
			line-height: 1;
			// letter-spacing: 1.4px;
		}
	}
	.map-info-desc {
		font-size: 16px;
		color: #3d3d3d;
		@media (max-width:767px) {
			line-height: 1;
		}
	}
	.banner {
		@media (max-width:767px) {
			margin-top: 0;
		}
	}
}
.map-right {
	margin-left: 20px;
	@media (max-width:767px) {
		margin-left: 0;
	}
}
