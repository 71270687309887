@charset "UTF-8";
.footer {
  color: #3d3d3d;
  position: relative;
  display: flex;
  padding: 30px 5px 15px;
  border-top: 1px solid #efefef; }
  @media (max-width: 979px) {
    .footer {
      padding: 28px 20px 15px; } }
  @media (max-width: 767px) {
    .footer {
      flex-direction: column;
      padding: 30px 15px 120px; } }
  .footer p {
    margin: 0;
    padding: 0; }
  .footer .footer-left {
    flex-shrink: 0;
    width: 180px;
    margin-right: 73px; }
    @media (max-width: 767px) {
      .footer .footer-left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px; } }
    .footer .footer-left .logo {
      margin-bottom: 5px; }
      @media (max-width: 979px) {
        .footer .footer-left .logo {
          margin-bottom: 0; } }
    .footer .footer-left .footer-copy {
      font-size: 14px; }
  .footer .footer-right {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 979px) {
      .footer .footer-right {
        flex-direction: column; } }
    .footer .footer-right .footer-nav {
      display: flex;
      flex-direction: column; }
      .footer .footer-right .footer-nav-wrapper {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 979px) {
          .footer .footer-right .footer-nav-wrapper {
            margin-bottom: 20px; } }
        @media (max-width: 767px) {
          .footer .footer-right .footer-nav-wrapper {
            flex-direction: column;
            margin-bottom: 25px; } }
      .footer .footer-right .footer-nav .footer-nav-title {
        font-size: 16px;
        margin: 0 0 6px 0; }
        @media (max-width: 979px) {
          .footer .footer-right .footer-nav .footer-nav-title {
            margin: 0; } }
      .footer .footer-right .footer-nav .footer-nav-items {
        margin: 0;
        padding: 0;
        list-style: none; }
        .footer .footer-right .footer-nav .footer-nav-items li {
          margin-bottom: 7px; }
          @media (max-width: 979px) {
            .footer .footer-right .footer-nav .footer-nav-items li {
              margin-bottom: 3px; } }
          .footer .footer-right .footer-nav .footer-nav-items li a {
            font-size: 14px;
            color: #0E96D2; }
      .footer .footer-right .footer-nav .footer-nav-products {
        flex-grow: 1; }
        .footer .footer-right .footer-nav .footer-nav-products .footer-nav-items {
          column-count: 2;
          column-gap: 40px; }
          @media (max-width: 979px) {
            .footer .footer-right .footer-nav .footer-nav-products .footer-nav-items {
              column-gap: unset; } }
      .footer .footer-right .footer-nav .footer-nav-services {
        margin-left: 22%; }
        @media (max-width: 979px) {
          .footer .footer-right .footer-nav .footer-nav-services {
            margin-left: 12%;
            margin-right: 16%; } }
        @media (max-width: 767px) {
          .footer .footer-right .footer-nav .footer-nav-services {
            margin-top: 20px;
            margin-left: 0;
            margin-right: 0; } }
    .footer .footer-right .footer-contacts {
      flex-shrink: 0;
      margin-left: 60px;
      margin-right: 15px; }
      @media (max-width: 979px) {
        .footer .footer-right .footer-contacts {
          margin-left: 0; } }
      @media (max-width: 767px) {
        .footer .footer-right .footer-contacts {
          margin-right: 0; } }
      @media (max-width: 979px) {
        .footer .footer-right .footer-contacts .footer-contacts-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; } }
      @media (max-width: 767px) {
        .footer .footer-right .footer-contacts .footer-contacts-wrap {
          justify-content: flex-start; } }
      .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item {
        flex-shrink: 0;
        margin-bottom: 3px; }
        @media (max-width: 979px) {
          .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item {
            margin-bottom: 0; } }
        @media (max-width: 767px) {
          .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item:not(:last-child) {
            margin-right: 20px; } }
        @media (max-width: 425px) {
          .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item:nth-child(2) {
            margin-right: 0; } }
        .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item p {
          margin: 0 !important;
          color: #838282;
          font-size: 14px; }
        .footer .footer-right .footer-contacts .footer-contacts-wrap .footer-contacts-item a {
          font-size: 14px;
          font-weight: bold;
          color: #3d3d3d;
          text-decoration: none;
          letter-spacing: 0.9px; }
      .footer .footer-right .footer-contacts .requestPopup {
        width: 145px;
        height: 40px;
        margin-top: 10px;
        color: #fff;
        background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%); }
        .footer .footer-right .footer-contacts .requestPopup:hover {
          background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%); }
        @media (max-width: 979px) {
          .footer .footer-right .footer-contacts .requestPopup {
            margin-top: 5px;
            margin-bottom: 18px; } }
        @media (max-width: 767px) {
          .footer .footer-right .footer-contacts .requestPopup {
            margin-bottom: 29px; } }
  .footer .footer-address-mobile {
    display: none; }
    @media (max-width: 979px) {
      .footer .footer-address-mobile {
        display: block; } }
    .footer .footer-address-mobile .charge-point {
      width: 273px;
      margin-top: 15px;
      margin-bottom: 0; }
      @media (max-width: 979px) {
        .footer .footer-address-mobile .charge-point {
          margin-bottom: 0;
          margin-top: 7px; } }
      .footer .footer-address-mobile .charge-point a {
        white-space: nowrap; }
  .footer .footer-address .charge-point {
    width: 273px;
    margin-top: 15px;
    margin-bottom: 0; }
    @media (max-width: 979px) {
      .footer .footer-address .charge-point {
        margin-bottom: 0;
        margin-top: 7px; } }
    .footer .footer-address .charge-point span {
      white-space: nowrap; }
  .footer .footer-address p {
    font-size: 14px; }
  @media (max-width: 979px) {
    .footer .footer-address {
      display: none; } }
  .footer .footer-support {
    position: absolute;
    top: 120px;
    left: 0; }
    @media (max-width: 979px) {
      .footer .footer-support {
        left: 20px; } }
    @media (max-width: 767px) {
      .footer .footer-support {
        top: unset;
        left: 15px;
        bottom: 15px; } }
    .footer .footer-support a {
      display: block; }
    .footer .footer-support p {
      font-size: 14px; }

/* =E-mail-form
--------------------------------------------------------------------*/
.contact-form {
  display: block;
  margin: 10px 0; }

.contact-form label {
  float: left;
  width: 150px;
  /*150px*/
  text-align: right;
  padding-right: 5px;
  padding-top: 2px;
  font-weight: bold;
  color: #333; }

.contact-form label span {
  font-weight: normal; }

.contact-form p input,
.contact-form p button {
  /*margin-right: 30px;*/
  /*margin-right: 186px;*/
  margin-left: 160px;
  width: 120px;
  background: #eee;
  border: solid 1px #000;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 12px; }

.contact-form p input:hover,
.contact-form p button:hover {
  background: #fff;
  border: solid 1px #67594c;
  cursor: pointer; }

* html .contact-form .submit {
  margin-left: 159px;
  padding: 2px 0; }

.contact-form div {
  margin: 4px 0; }

.contact-form p {
  clear: both;
  /*text-align: right;*/
  text-align: left;
  padding: 10px 0 0 0; }

.contact-form div input {
  /*width: 250px;*/
  width: 350px; }

.contact-form div textarea {
  /*width: 250px;*/
  width: 350px;
  height: 150px; }

.contact-form div input,
.contact-form div textarea {
  background: #fafafa;
  /*f6f1dc*/
  border: solid 1px #000;
  font-size: 1em;
  padding: 2px 4px;
  font-family: Tahoma;
  color: #000; }

.contact-form div input:hover,
.contact-form div textarea:hover {
  background: #fff;
  border: solid 1px #67594c; }

.contact-form div input:focus,
.contact-form div textarea:focus {
  background: #fff;
  border: solid 1px #67594c; }

.contact-form div input.invalid,
.contact-form div textarea.invalid {
  border: solid 1px red !important; }

.contact-form .submit {
  color: #000; }

.contact-form div input[type="checkbox"] {
  width: auto; }

.contact-form div.contact-email-copy {
  height: 10px; }

.contact-form div.contact-email-copy label {
  width: auto;
  float: right; }

.contact-form div.contact-email-copy input {
  width: auto;
  float: right;
  margin-right: 186px; }

* html .contact-form div.contact-email-copy input {
  border: none; }

.form-required {
  color: #656565;
  text-align: left;
  margin: 5px 0 18px 0; }

/* =Fox-form
--------------------------------------------------------------------*/
.fcaptchacontainer div {
  float: left;
  margin: 0 5px; }

.fcaptchafieldcontainer #fcaptcha {
  border: 0;
  padding: 0; }

.fcaptchacontainer #reloadbtn {
  width: 16px;
  height: 16px;
  border: 0;
  padding: 0; }

.fcaptchacontainer .fcaptchainputcontainer input {
  width: 190px; }

/* =Subscription
--------------------------------------------------------------------*/
.modns {
  clear: both; }

.subscribe span,
.modns span {
  display: block;
  text-align: center;
  padding: 0 10px; }

#right form {
  width: 217px;
  margin: 5px;
  height: 89px;
  background: #f2f2f2 url("/assets/i/mailback.gif") 0 0 no-repeat;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #898989;
  padding: 16px 13px 10px 20px; }

#right form h3 {
  font-weight: normal;
  color: #3e3e3e;
  padding: 0; }

#right form #send {
  float: right;
  width: 80px;
  height: 28px;
  font-size: 11px;
  text-align: center;
  background: transparent url("/assets/i/mail_btn.gif") 0 0 no-repeat;
  border: 0;
  padding: 0 0 4px 3px;
  margin: 0;
  color: #fff;
  cursor: pointer; }

#right form #send:hover {
  background-position: bottom; }

#right form #field {
  width: 125px;
  margin: 3px 0;
  background: #fafafa;
  border: solid 1px #999;
  font-size: 1em;
  padding: 4px 4px;
  font-family: Tahoma;
  color: #333; }

#right form #field:hover,
#right form #field:focus {
  background: #fff;
  border: solid 1px #333; }

.feedback-form {
  order: 3;
  margin-top: 50px;
  padding: 40px;
  background-color: #efefef;
  color: #3D3D3D;
  box-sizing: border-box; }
  @media (max-width: 979px) {
    .feedback-form {
      padding: 24px 15px 30px; } }
  @media (max-width: 510px) {
    .feedback-form {
      margin: 0;
      padding: 20px 15px 25px; } }
  .feedback-form form {
    margin-top: 20px; }
    @media (max-width: 979px) {
      .feedback-form form {
        margin-top: 14px; } }
    @media (max-width: 767px) {
      .feedback-form form {
        margin-top: 18px; } }
    .feedback-form form .form-group {
      margin-bottom: 0 !important; }
      @media (max-width: 979px) {
        .feedback-form form .form-group {
          width: 290px;
          margin: 0 auto; } }
      @media (max-width: 425px) {
        .feedback-form form .form-group {
          width: 100%; } }
      .feedback-form form .form-group input {
        width: 290px;
        height: 45px;
        margin-bottom: 10px !important; }
        @media (max-width: 425px) {
          .feedback-form form .form-group input {
            width: 100%; } }
      .feedback-form form .form-group input, .feedback-form form .form-group textarea {
        padding-left: 20px !important;
        color: #3D3D3D;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif; }
      .feedback-form form .form-group textarea {
        height: 110px; }
      .feedback-form form .form-group button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 45px;
        margin: 0 auto;
        font-size: 16px;
        color: #fff;
        background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
        border-radius: 10px; }
        .feedback-form form .form-group button:hover {
          background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%); }
      .feedback-form form .form-group-wrapper {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 979px) {
          .feedback-form form .form-group-wrapper {
            width: 290px;
            flex-direction: column;
            margin: 0 auto; } }
        @media (max-width: 425px) {
          .feedback-form form .form-group-wrapper {
            width: 100%; } }

.feedback-form-title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
  text-align: center; }
  @media (max-width: 979px) {
    .feedback-form-title {
      margin-bottom: 3px; } }

.feedback-form-desc {
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.4px; }
  @media (max-width: 767px) {
    .feedback-form-desc {
      line-height: 1.2 !important; } }

.popup-main-form {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  color: #3d3d3d; }
  .popup-main-form-title {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center; }
  .popup-main-form-desc {
    margin: 0 0 20px 0;
    font-size: 14px; }
  .popup-main-form-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    padding: 40px 60px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #000;
    box-sizing: border-box; }
    .popup-main-form-content form .form-group button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 45px;
      margin: 0 auto;
      font-size: 16px;
      color: #fff;
      background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
      border-radius: 10px; }
    .popup-main-form-content form .form-group input {
      height: 45px; }
    .popup-main-form-content form .form-group input, .popup-main-form-content form .form-group textarea {
      color: #3D3D3D;
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif; }
    .popup-main-form-content-success {
      display: none; }
      .popup-main-form-content-success .popup-main-form-desc {
        margin-bottom: 0;
        text-align: center; }
  .popup-main-form-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    background: url("/assets/images/close_form.svg") no-repeat center center;
    background-size: contain;
    cursor: pointer; }
    .popup-main-form-close:hover {
      background: url("/assets/images/close_form_blue.png") no-repeat center; }

@media screen and (max-width: 979px) {
  .main-menu-wrapper {
    padding: 0 20px; } }

.main-menu-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.main-menu-wrapper li {
  line-height: 1.1; }

.main-menu-wrapper a {
  font-size: 14px !important;
  text-decoration: none !important;
  color: #fff !important;
  line-height: 1.1; }
  @media screen and (max-width: 979px) {
    .main-menu-wrapper a {
      font-size: 13px !important; } }

.main-menu-top .parent-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 49px;
  background-color: #373737;
  border-radius: 10px; }
  .main-menu-top .parent-menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 3%; }
    .main-menu-top .parent-menu-item.active {
      border-radius: 10px;
      background-color: #12A4DE;
      transform: scaleY(1.1); }
    .main-menu-top .parent-menu-item ul {
      display: none; }
    .main-menu-top .parent-menu-item:not(.active) a {
      transition: text-shadow 0.1s; }
      .main-menu-top .parent-menu-item:not(.active) a::after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
        margin-bottom: -1px; }
      .main-menu-top .parent-menu-item:not(.active) a:hover {
        text-shadow: 0 0 .9px #fff, 0 0 .9px #fff; }
  .main-menu-top .parent-menu-list.active {
    position: relative;
    border-radius: 10px 10px 0 0;
    background-color: #12A4DE;
    transform: scaleY(1.1); }
    .main-menu-top .parent-menu-list.active::before, .main-menu-top .parent-menu-list.active::after {
      content: "";
      position: absolute;
      height: 10px;
      width: 20px;
      bottom: 1px; }
    .main-menu-top .parent-menu-list.active::after {
      right: -20px;
      border-radius: 0 0 0 10px;
      box-shadow: -10px 1px 0 0 #12A4DE; }
    .main-menu-top .parent-menu-list.active::before {
      left: -20px;
      border-radius: 0 0 10px 0;
      box-shadow: 10px 1px 0 0 #12A4DE; }

.main-menu-sub .parent-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 20px;
  background-color: #12A4DE;
  border-radius: 0 0 10px 10px;
  gap: 10px 0; }
  .main-menu-sub .parent-menu > li {
    display: flex; }
    .main-menu-sub .parent-menu > li:not(.parent-menu-list):hover a {
      text-decoration: underline !important; }
    .main-menu-sub .parent-menu > li > a {
      white-space: nowrap; }
  .main-menu-sub .parent-menu .child-menu {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 0; }
    .main-menu-sub .parent-menu .child-menu li.active a {
      font-weight: bold; }
    .main-menu-sub .parent-menu .child-menu li.first {
      margin-right: 15px; }
      .main-menu-sub .parent-menu .child-menu li.first + li.last {
        padding-left: 15px;
        border-left: 1px solid #fff; }
    .main-menu-sub .parent-menu .child-menu li:not(.first):not(.last) {
      margin-right: 15px;
      padding-left: 15px;
      padding-right: 15px;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff; }
      .main-menu-sub .parent-menu .child-menu li:not(.first):not(.last) + li {
        border-left: none;
        padding-left: 0; }
    .main-menu-sub .parent-menu .child-menu li:hover a {
      text-decoration: underline !important; }
  .main-menu-sub .parent-menu > li.first {
    margin-right: 18px; }
  .main-menu-sub .parent-menu > li:not(.first):not(.last) {
    margin-right: 18px;
    padding-left: 18px;
    padding-right: 18px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff; }
    .main-menu-sub .parent-menu > li:not(.first):not(.last) + li {
      border-left: none;
      padding-left: 0; }
  .main-menu-sub .parent-menu-list {
    width: 100%;
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-right: none !important;
    border-left: none !important; }
    .main-menu-sub .parent-menu-list > a {
      font-weight: bold; }
  .main-menu-sub .parent-menu::before, .main-menu-sub .parent-menu::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 20px; }
  .main-menu-sub .parent-menu::after {
    top: -15px;
    right: -4px;
    border-radius: 0 0 0 10px;
    box-shadow: -10px 1px 0 0 #12A4DE;
    transform: rotate(-90deg); }
  .main-menu-sub .parent-menu::before {
    top: -15px;
    left: -5px;
    border-radius: 0 0 10px 0;
    box-shadow: 12px 0px 0 0 #12a4de;
    transform: rotate(90deg); }

/*Main styles*/
#map_container img {
  border: 0; }

#map_container {
  position: relative;
  margin: 20px auto;
  width: 421px;
  height: 391px;
  z-index: 2;
  overflow: visible; }
  @media (max-width: 979px) {
    #map_container {
      zoom: 0.7; } }

#map_container #map_img {
  padding: 0;
  margin: 0;
  border: 0;
  width: 421px;
  height: 391px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3; }

#map_container #map_header {
  margin: 5px 0 0 -10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4; }

#map_container #map_header h3 {
  padding: 0; }

/*City link styles*/
#map_container a,
#map_container a:link,
#map_container a:visited,
#map_container a:hover,
#map_container a:active {
  position: absolute;
  z-index: 5;
  display: block;
  color: #000;
  font-size: 11px;
  font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif;
  background: transparent url("/assets/i/point.png") 0 0 no-repeat;
  padding: 0 0 0 20px;
  margin: 0;
  text-decoration: none;
  height: 16px;
  line-height: 14px;
  text-shadow: 1px 1px 2px #fff; }

#map_container a:hover {
  color: #fff;
  background-position: 0 100%; }

#map_container a.rightbg {
  background-position: 100% 0;
  padding: 0 20px 0 0; }

#map_container a.rightbg:hover {
  background-position: 100% 100%; }

#map_container a.hilight {
  color: #fff;
  background-position: 0 100%; }

#map_container a.hilight.rightbg {
  background-position: 100% 100%; }

#map_container #shigoni {
  top: 153px;
  left: 14px; }

#map_container #ilmen {
  top: 200px;
  left: 58px; }

#map_container #privolzhe {
  top: 225px;
  left: 53px; }

#map_container #hvorostianka {
  top: 262px;
  left: 85px; }

#map_container #pestravka {
  top: 300px;
  left: 174px; }

#map_container #samara {
  top: 181px;
  left: 195px;
  font-size: 12px;
  background: transparent url("/assets/i/pointbig.png") 0 0 no-repeat;
  height: 20px;
  line-height: 20px; }

#map_container #samara:hover {
  background-position: 0 100%; }

#map_container #novok {
  top: 207px;
  left: 170px; }

#map_container #chapaevsk {
  top: 225px;
  left: 145px; }

#map_container #bezenchuk {
  top: 212px;
  left: 105px; }

#map_container #pohvistnevo {
  top: 136px;
  left: 297px; }

/*Popup styles*/
#map_container #map_popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  display: none;
  width: 310px;
  /*Hack for Safari & Chrome =*/ }

#map_container #map_popup img {
  border: 0 none;
  background: none;
  margin: 0;
  padding: 0; }

#map_container #map_popup td.top-left {
  background: url("/assets/i/map_popup/top-left.png") 100% 100% no-repeat; }

#map_container #map_popup td.top {
  background: url("/assets/i/map_popup/top-middle.png") 100% 100% repeat-x;
  width: auto; }

#map_container #map_popup td.top-right {
  background: url("/assets/i/map_popup/top-right.png") 0 100% no-repeat; }

#map_container #map_popup td.left {
  background: url("/assets/i/map_popup/middle-left.png") 100% 100% repeat-y;
  height: auto;
  vertical-align: bottom;
  float: none;
  margin: 0;
  font-size: 0px;
  line-height: 0px; }

#map_container #map_popup td.middle {
  background: #fff url("/assets/i/map_popup/img-middle.png") 100% 100% repeat-x;
  height: auto;
  width: auto; }

#map_container #map_popup td.right {
  background: url("/assets/i/map_popup/middle-right.png") 0 100% repeat-y;
  height: auto;
  vertical-align: bottom;
  float: none;
  margin: 0;
  font-size: 0px;
  line-height: 0px; }

#map_container #map_popup td.bottom-left {
  background: url("/assets/i/map_popup/bottom-left.png") 100% 0 no-repeat; }

#map_container #map_popup td.bottom {
  background: url("/assets/i/map_popup/bottom-middle.png") 100% 0 repeat-x;
  width: auto;
  overflow: visible; }

#map_container #map_popup td.bottom-right {
  background: url("/assets/i/map_popup/bottom-right.png") 0 0 no-repeat; }

#map_container #map_popup td.param {
  width: 10px;
  height: 10px; }

/*Info styles*/
#map_container #map_info {
  display: none; }

#map_container #map_info div {
  display: none; }

#map_container #map_popup div.info {
  border-bottom: dashed 1px #ccc;
  font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif;
  margin: 5px 0;
  padding: 0 5px 20px 5px;
  min-height: 45px; }

#map_container #map_popup div.left {
  float: left;
  /*max-width: 150px;*/
  width: 150px;
  padding: 0 5px;
  margin: 0; }

#map_container #map_popup div.right {
  float: right;
  /*max-width: 110px;*/
  width: 110px;
  padding: 0 5px;
  margin: 0; }

#map_container #map_popup h5 {
  color: #0f9fda;
  font-size: 11px;
  padding: 0;
  margin: 0;
  background: none; }

#map_container #map_popup div.left p {
  font-size: 12px; }

#map_container #map_popup div.left p strong {
  font-size: 13px; }

#map_container #map_popup div.right p {
  font-size: 10px; }

#map_container #map_popup div.info a,
#map_container #map_popup div.info a:link,
#map_container #map_popup div.info a:visited,
#map_container #map_popup div.info a:hover,
#map_container #map_popup div.info a:active {
  display: block;
  width: 260px;
  background: none;
  color: #0081b9;
  text-decoration: underline;
  text-align: center; }

#map_container #map_popup div.info a:hover {
  text-decoration: none; }

/*Tip*/
#map_container #tip {
  width: 220px;
  font-size: 9px;
  color: #aeaeae;
  float: left; }

#map_container #tip:before {
  content: "*";
  margin: 0 0 0 -8px;
  padding: 0 2px 0 0; }

.mapblock-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px; }
  @media (max-width: 979px) {
    .mapblock-wrapper {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .mapblock-wrapper {
      flex-direction: column; } }
  .mapblock-wrapper h2 {
    margin-top: 0 !important; }
  .mapblock-wrapper h3 {
    margin-bottom: 26px !important; }
    @media (max-width: 767px) {
      .mapblock-wrapper h3 {
        margin-bottom: 18px !important;
        font-size: 22px !important;
        line-height: 1.1 !important; } }
  .mapblock-wrapper .banner {
    margin-top: 0; }
    .mapblock-wrapper .banner img {
      padding: 0 !important;
      border: none !important; }

.map-wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  @media (max-width: 979px) {
    .map-wrapper {
      align-items: unset; } }
  @media (max-width: 767px) {
    .map-wrapper {
      flex-direction: column;
      align-items: center; } }
  .map-wrapper #map_container {
    margin: 0; }
  .map-wrapper .map-info {
    display: flex;
    flex-direction: column;
    margin-left: 35px; }
    @media (max-width: 979px) {
      .map-wrapper .map-info {
        margin-left: 15px; } }
    @media (max-width: 767px) {
      .map-wrapper .map-info {
        margin-left: 0; } }
  .map-wrapper .map-info-item:not(:last-child) {
    margin-bottom: 18px; }
    @media (max-width: 979px) {
      .map-wrapper .map-info-item:not(:last-child) {
        margin-bottom: 9px; } }
  .map-wrapper .map-info-value {
    font-size: 35px !important;
    font-weight: bold !important;
    color: #0E96D2 !important; }
    @media (max-width: 979px) {
      .map-wrapper .map-info-value {
        font-size: 26px !important;
        line-height: 24px; } }
    @media (max-width: 767px) {
      .map-wrapper .map-info-value {
        margin-bottom: 8px;
        font-size: 21px !important;
        line-height: 1; } }
  .map-wrapper .map-info-desc {
    font-size: 16px;
    color: #3d3d3d; }
    @media (max-width: 767px) {
      .map-wrapper .map-info-desc {
        line-height: 1; } }
  @media (max-width: 767px) {
    .map-wrapper .banner {
      margin-top: 0; } }

.map-right {
  margin-left: 20px; }
  @media (max-width: 767px) {
    .map-right {
      margin-left: 0; } }

div.light_rounded .pp_top .pp_left {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat; }

div.light_rounded .pp_top .pp_middle {
  background: #fff; }

div.light_rounded .pp_top .pp_right {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat; }

div.light_rounded .pp_content .ppt {
  color: #000; }

div.light_rounded .pp_content_container .pp_left, div.light_rounded .pp_content_container .pp_right {
  background: #fff; }

div.light_rounded .pp_content {
  background-color: #fff; }

div.light_rounded .pp_next:hover {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
  cursor: pointer; }

div.light_rounded .pp_previous:hover {
  background: url(../images/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
  cursor: pointer; }

div.light_rounded .pp_expand {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
  cursor: pointer; }

div.light_rounded .pp_expand:hover {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
  cursor: pointer; }

div.light_rounded .pp_contract {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
  cursor: pointer; }

div.light_rounded .pp_contract:hover {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
  cursor: pointer; }

div.light_rounded .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
  cursor: pointer; }

div.light_rounded #pp_full_res .pp_inline {
  color: #000; }

div.light_rounded .pp_gallery a.pp_arrow_previous, div.light_rounded .pp_gallery a.pp_arrow_next {
  margin-top: 12px !important; }

div.light_rounded .pp_nav .pp_play {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.light_rounded .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.light_rounded .pp_arrow_previous {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat; }

div.light_rounded .pp_arrow_previous.disabled {
  background-position: 0 -87px;
  cursor: default; }

div.light_rounded .pp_arrow_next {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat; }

div.light_rounded .pp_arrow_next.disabled {
  background-position: -22px -87px;
  cursor: default; }

div.light_rounded .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat; }

div.light_rounded .pp_bottom .pp_middle {
  background: #fff; }

div.light_rounded .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat; }

div.light_rounded .pp_loaderIcon {
  background: url(../images/prettyPhoto/light_rounded/loader.gif) center center no-repeat; }

div.dark_rounded .pp_top .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat; }

div.dark_rounded .pp_top .pp_middle {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat; }

div.dark_rounded .pp_top .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat; }

div.dark_rounded .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y; }

div.dark_rounded .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y; }

div.dark_rounded .pp_content {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat; }

div.dark_rounded .pp_next:hover {
  background: url(../images/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_previous:hover {
  background: url(../images/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_expand {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_expand:hover {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_contract {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_contract:hover {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
  cursor: pointer; }

div.dark_rounded .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
  cursor: pointer; }

div.dark_rounded .currentTextHolder {
  color: #c4c4c4; }

div.dark_rounded .pp_description {
  color: #fff; }

div.dark_rounded #pp_full_res .pp_inline {
  color: #fff; }

div.dark_rounded .pp_gallery a.pp_arrow_previous, div.dark_rounded .pp_gallery a.pp_arrow_next {
  margin-top: 12px !important; }

div.dark_rounded .pp_nav .pp_play {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.dark_rounded .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.dark_rounded .pp_arrow_previous {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat; }

div.dark_rounded .pp_arrow_previous.disabled {
  background-position: 0 -87px;
  cursor: default; }

div.dark_rounded .pp_arrow_next {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat; }

div.dark_rounded .pp_arrow_next.disabled {
  background-position: -22px -87px;
  cursor: default; }

div.dark_rounded .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat; }

div.dark_rounded .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat; }

div.dark_rounded .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat; }

div.dark_rounded .pp_loaderIcon {
  background: url(../images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat; }

div.dark_square .pp_left, div.dark_square .pp_middle, div.dark_square .pp_right, div.dark_square .pp_content {
  background: url(../images/prettyPhoto/dark_square/contentPattern.png) top left repeat; }

div.dark_square .currentTextHolder {
  color: #c4c4c4; }

div.dark_square .pp_description {
  color: #fff; }

div.dark_square .pp_loaderIcon {
  background: url(../images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat; }

div.dark_square .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y; }

div.dark_square .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y; }

div.dark_square .pp_expand {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
  cursor: pointer; }

div.dark_square .pp_expand:hover {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
  cursor: pointer; }

div.dark_square .pp_contract {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
  cursor: pointer; }

div.dark_square .pp_contract:hover {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
  cursor: pointer; }

div.dark_square .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
  cursor: pointer; }

div.dark_square #pp_full_res .pp_inline {
  color: #fff; }

div.dark_square .pp_gallery a.pp_arrow_previous, div.dark_square .pp_gallery a.pp_arrow_next {
  margin-top: 12px !important; }

div.dark_square .pp_nav .pp_play {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.dark_square .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.dark_square .pp_arrow_previous {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat; }

div.dark_square .pp_arrow_previous.disabled {
  background-position: 0 -87px;
  cursor: default; }

div.dark_square .pp_arrow_next {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat; }

div.dark_square .pp_arrow_next.disabled {
  background-position: -22px -87px;
  cursor: default; }

div.dark_square .pp_next:hover {
  background: url(../images/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
  cursor: pointer; }

div.dark_square .pp_previous:hover {
  background: url(../images/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
  cursor: pointer; }

div.light_square .pp_left, div.light_square .pp_middle, div.light_square .pp_right, div.light_square .pp_content {
  background: #fff; }

div.light_square .pp_content .ppt {
  color: #000; }

div.light_square .pp_expand {
  background: url(../images/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
  cursor: pointer; }

div.light_square .pp_expand:hover {
  background: url(../images/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
  cursor: pointer; }

div.light_square .pp_contract {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
  cursor: pointer; }

div.light_square .pp_contract:hover {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
  cursor: pointer; }

div.light_square .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
  cursor: pointer; }

div.light_square #pp_full_res .pp_inline {
  color: #000; }

div.light_square .pp_gallery a.pp_arrow_previous, div.light_square .pp_gallery a.pp_arrow_next {
  margin-top: 12px !important; }

div.light_square .pp_nav .pp_play {
  background: url(../images/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.light_square .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px; }

div.light_square .pp_arrow_previous {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat; }

div.light_square .pp_arrow_previous.disabled {
  background-position: 0 -87px;
  cursor: default; }

div.light_square .pp_arrow_next {
  background: url(../images/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat; }

div.light_square .pp_arrow_next.disabled {
  background-position: -22px -87px;
  cursor: default; }

div.light_square .pp_next:hover {
  background: url(../images/prettyPhoto/light_square/btnNext.png) center right no-repeat;
  cursor: pointer; }

div.light_square .pp_previous:hover {
  background: url(../images/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
  cursor: pointer; }

div.facebook .pp_top .pp_left {
  background: url(../images/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat; }

div.facebook .pp_top .pp_middle {
  background: url(../images/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x; }

div.facebook .pp_top .pp_right {
  background: url(../images/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat; }

div.facebook .pp_content .ppt {
  color: #000; }

div.facebook .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y; }

div.facebook .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y; }

div.facebook .pp_content {
  background: #fff; }

div.facebook .pp_expand {
  background: url(../images/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
  cursor: pointer; }

div.facebook .pp_expand:hover {
  background: url(../images/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
  cursor: pointer; }

div.facebook .pp_contract {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
  cursor: pointer; }

div.facebook .pp_contract:hover {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
  cursor: pointer; }

div.facebook .pp_close {
  width: 22px;
  height: 22px;
  background: url(../images/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
  cursor: pointer; }

div.facebook #pp_full_res .pp_inline {
  color: #000; }

div.facebook .pp_loaderIcon {
  background: url(../images/prettyPhoto/facebook/loader.gif) center center no-repeat; }

div.facebook .pp_arrow_previous {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px; }

div.facebook .pp_arrow_previous.disabled {
  background-position: 0 -96px;
  cursor: default; }

div.facebook .pp_arrow_next {
  background: url(../images/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px; }

div.facebook .pp_arrow_next.disabled {
  background-position: -32px -96px;
  cursor: default; }

div.facebook .pp_nav {
  margin-top: 0; }

div.facebook .pp_nav p {
  font-size: 15px;
  padding: 0 3px 0 4px; }

div.facebook .pp_nav .pp_play {
  background: url(../images/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
  height: 22px;
  width: 22px; }

div.facebook .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
  height: 22px;
  width: 22px; }

div.facebook .pp_next:hover {
  background: url(../images/prettyPhoto/facebook/btnNext.png) center right no-repeat;
  cursor: pointer; }

div.facebook .pp_previous:hover {
  background: url(../images/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
  cursor: pointer; }

div.facebook .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat; }

div.facebook .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x; }

div.facebook .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat; }

div.pp_pic_holder a:focus {
  outline: none; }

div.pp_overlay {
  background: #000;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9500; }

div.pp_pic_holder {
  display: none;
  position: absolute;
  width: 100px;
  z-index: 10000; }

.pp_top {
  height: 20px;
  position: relative; }

* html .pp_top {
  padding: 0 20px; }

.pp_top .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px; }

.pp_top .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px; }

* html .pp_top .pp_middle {
  left: 0;
  position: static; }

.pp_top .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px; }

.pp_content {
  height: 40px; }

.pp_fade {
  display: none; }

.pp_content_container {
  position: relative;
  text-align: left;
  width: 100%; }

.pp_content_container .pp_left {
  padding-left: 20px; }

.pp_content_container .pp_right {
  padding-right: 20px; }

.pp_content_container .pp_details {
  float: left;
  margin: 10px 0 2px 0; }

.pp_description {
  display: none;
  margin: 0 0 5px 0; }

.pp_nav {
  clear: left;
  float: left;
  margin: 3px 0 0 0; }

.pp_nav p {
  float: left;
  margin: 2px 4px; }

.pp_nav .pp_play, .pp_nav .pp_pause {
  float: left;
  margin-right: 4px;
  text-indent: -10000px; }

a.pp_arrow_previous, a.pp_arrow_next {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px; }

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000; }

.pp_gallery {
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000; }

.pp_gallery ul {
  float: left;
  height: 35px;
  margin: 0 0 0 5px;
  overflow: hidden;
  padding: 0;
  position: relative; }

.pp_gallery ul a {
  border: 1px #000 solid;
  border: 1px rgba(0, 0, 0, 0.5) solid;
  display: block;
  float: left;
  height: 33px;
  overflow: hidden; }

.pp_gallery ul a:hover, .pp_gallery li.selected a {
  border-color: #fff; }

.pp_gallery ul a img {
  border: 0; }

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0; }

.pp_gallery li.default a {
  background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
  display: block;
  height: 33px;
  width: 50px; }

.pp_gallery li.default a img {
  display: none; }

.pp_gallery .pp_arrow_previous, .pp_gallery .pp_arrow_next {
  margin-top: 7px !important; }

a.pp_next {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: right;
  height: 100%;
  text-indent: -10000px;
  width: 49%; }

a.pp_previous {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: left;
  height: 100%;
  text-indent: -10000px;
  width: 49%; }

a.pp_expand, a.pp_contract {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000; }

a.pp_close {
  display: block;
  float: right;
  line-height: 22px;
  text-indent: -10000px; }

.pp_bottom {
  height: 20px;
  position: relative; }

* html .pp_bottom {
  padding: 0 20px; }

.pp_bottom .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px; }

.pp_bottom .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px; }

* html .pp_bottom .pp_middle {
  left: 0;
  position: static; }

.pp_bottom .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px; }

.pp_loaderIcon {
  display: block;
  height: 24px;
  left: 50%;
  margin: -12px 0 0 -12px;
  position: absolute;
  top: 50%;
  width: 24px; }

#pp_full_res {
  line-height: 1 !important; }

#pp_full_res .pp_inline {
  text-align: left; }

#pp_full_res .pp_inline p {
  margin: 0 0 15px 0; }

div.ppt {
  color: #fff;
  display: none;
  font-size: 17px;
  margin: 0 0 5px 15px;
  z-index: 9999; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  display: inline-block; }

* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

.main-page .bg-main h1 {
  font-size: 30px;
  font-weight: 400;
  padding: 0;
  margin: 30px 0 22px 0;
  color: #3D3D3D;
  line-height: 1.1; }
  @media (max-width: 767px) {
    .main-page .bg-main h1 {
      margin: 30px 0 20px 0;
      font-size: 24px;
      line-height: 1; } }

.main-page .bg-main h2 {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 26px;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 1.1; }
  @media (max-width: 979px) {
    .main-page .bg-main h2 {
      line-height: 1.2; } }

.main-page .bg-main h3 {
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 1.1; }

.main-page .bg-main p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px;
  line-height: 1.4;
  color: #3D3D3D; }
  @media (max-width: 979px) {
    .main-page .bg-main p {
      line-height: 1.5; } }

.main-page .bg-main div {
  color: #3D3D3D; }

.main-page #lr-wrap {
  margin-bottom: 20px; }
  @media (max-width: 979px) {
    .main-page #lr-wrap {
      padding-bottom: 0; } }

.item-page-main {
  margin-bottom: 50px; }
  @media (max-width: 979px) {
    .item-page-main {
      margin-bottom: 36px; } }
  @media (max-width: 767px) {
    .item-page-main {
      margin-bottom: 40px; } }

/*new left menu*/
.menuProd {
  padding: 0 18px 17px 13px;
  border-left: solid 1px #fafafa;
  border-right: solid 1px #fafafa;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #f4f4f4;
  z-index: 1;
  box-sizing: border-box;
  margin-bottom: 20px; }

.menuProd ul li.parent {
  background: none !important;
  margin: 0 !important;
  padding: 0 !important; }

.menuProd ul li.parent a {
  text-decoration: underline;
  color: #0081b9;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 25px 0 8px 12px;
  line-height: 22px;
  display: block;
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif; }

.menuProd ul li.active a {
  color: #3d3d3d;
  text-decoration: none; }

.menuProd ul h2 {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 25px 0 8px 12px;
  line-height: 22px;
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif; }

.menuProd ul li.parent a:hover {
  text-decoration: none; }

.menuProd ul li.parent ul li a {
  color: #0081b9;
  text-decoration: underline;
  font-size: 11px;
  outline: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  line-height: normal; }

.menuProd ul li ul li.active a {
  color: #3d3d3d;
  text-decoration: none; }

.menuProd ul li.parent ul li a:hover {
  text-decoration: none; }

#breadcrumb ul {
  display: flex;
  margin: 10px 0 0 0 !important;
  padding: 0px 0 5px 0 !important;
  border-bottom: 1px solid #dedede; }

#breadcrumb ul li {
  background: none !important;
  margin: 0 !important;
  padding: 0px 5px !important; }

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #0081b9;
  text-decoration: underline;
  font-size: 1em;
  outline: none; }

a:hover {
  text-decoration: none; }

/* General */
.charge-point {
  display: flex;
  margin-bottom: 7px;
  padding: 10px;
  border-radius: 10px;
  color: #0F9EDA !important;
  background-color: #f4f4f4;
  font-size: 14px !important; }
  .charge-point:hover {
    color: #fff !important;
    background-color: #0EA7E4;
    text-decoration: underline !important; }
    .charge-point:hover::before {
      background-image: url("/assets/images/charge-point-white.svg"); }
  @media screen and (max-width: 979px) {
    .charge-point {
      padding: 8px; } }
  .charge-point::before {
    content: '';
    display: block;
    width: 14px;
    height: 18px;
    margin-right: 5px;
    background-image: url("/assets/images/charge-point.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }

.requestPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 150px;
  color: #3d3d3d;
  font-size: 16px;
  background-color: #FFB800;
  border-radius: 10px;
  cursor: pointer;
  user-select: none; }
  .requestPopup:hover {
    background-color: #FFD25E; }

.top {
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .top {
      display: none; } }
  .top .custom {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 18px 0; }
    @media screen and (max-width: 979px) {
      .top .custom {
        padding: 20px; } }
  .top .logo {
    margin-right: 30px; }
    @media screen and (max-width: 979px) {
      .top .logo {
        margin-right: 0; } }
    .top .logo a {
      text-decoration: none; }
    .top .logo .logo-link {
      display: block;
      margin: 0 0 10px 0; }
      .top .logo .logo-link img {
        width: 133px;
        height: 40px; }
    .top .logo .logo-title {
      margin: 0;
      color: #838282;
      line-height: 1.4;
      font-size: 11px; }
  .top .contacts {
    display: flex;
    color: #b0aeae;
    font-size: 11px; }
    @media screen and (max-width: 979px) {
      .top .contacts {
        flex-direction: column; } }
    .top .contacts .contacts-left {
      margin-right: 30px; }
      @media screen and (max-width: 979px) {
        .top .contacts .contacts-left {
          margin-right: 0; } }
    .top .contacts .contacts-right {
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 979px) {
        .top .contacts .contacts-right {
          flex-direction: row; } }
    .top .contacts .phone:first-child {
      margin-bottom: 7px; }
      @media screen and (max-width: 979px) {
        .top .contacts .phone:first-child {
          margin-bottom: 0;
          margin-right: 15px; } }
    .top .contacts .phone a {
      color: #3d3d3d;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.9px; }
    .top .contacts .phone p {
      margin: 0 !important;
      font-size: 14px;
      line-height: 1.2; }
    .top .contacts .email {
      font-size: 14px; }
      @media screen and (max-width: 979px) {
        .top .contacts .email {
          margin-bottom: 5px; } }
      .top .contacts .email a {
        color: #3d3d3d;
        text-decoration: none;
        font-weight: bold; }
      .top .contacts .email p {
        margin: 0 !important;
        line-height: 1.2; }
    .top .contacts .contacts-desc {
      color: #838282; }
  .top .ya-widget {
    position: relative;
    width: 150px;
    height: 50px;
    margin-bottom: 7px;
    background-color: #f6f6f6;
    pointer-events: all; }
  .top .ya-widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: pointer; }
  .top-mobile {
    display: none; }
    @media screen and (max-width: 767px) {
      .top-mobile {
        display: flex; } }
    .top-mobile a {
      text-decoration: none; }
    .top-mobile ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    .top-mobile .logo {
      margin-right: auto; }
      .top-mobile .logo .logo-link {
        display: block;
        width: 90px;
        height: 26px;
        margin: 0;
        background-image: url("/assets/images/logo-mobile.svg"); }
    .top-mobile .phones {
      position: relative;
      width: 19px;
      height: 19px;
      background-image: url("/assets/images/phone.svg"); }
      .top-mobile .phones.active {
        background-image: url("/assets/images/phone-active.svg"); }
    .top-mobile .tooltip-phones {
      position: absolute;
      top: 35px;
      right: -50px;
      display: none; }
      .top-mobile .tooltip-phones.active {
        display: flex;
        flex-direction: column;
        padding: 15px;
        background-color: #FFB800;
        border-radius: 10px;
        box-sizing: border-box; }
        .top-mobile .tooltip-phones.active::before {
          position: absolute;
          top: -10px;
          right: 50px;
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #ffb800; }
      .top-mobile .tooltip-phones .tooltip-phone:first-child {
        margin-bottom: 10px; }
      .top-mobile .tooltip-phones a {
        font-size: 16px;
        font-weight: bold;
        color: #3d3d3d;
        letter-spacing: 0.9px;
        white-space: nowrap; }
      .top-mobile .tooltip-phones p {
        margin: 0 !important;
        font-size: 14px;
        line-height: 1;
        color: #3d3d3d; }
    .top-mobile .burger {
      width: 24px;
      height: 17px;
      margin-left: 30px;
      background-image: url("/assets/images/burger.svg"); }
      .top-mobile .burger.active {
        background-image: url("/assets/images/burger-active.svg"); }
    .top-mobile .contacts {
      display: flex;
      margin-bottom: 20px; }
    .top-mobile .requestPopup {
      flex-shrink: 0;
      width: 120px;
      height: 33px;
      font-size: 14px; }
    .top-mobile .charge-point {
      width: 271px;
      margin-bottom: 10px;
      padding: 9px;
      background-color: #6c6c6c;
      color: #fff !important;
      text-decoration: underline !important; }
      .top-mobile .charge-point::before {
        background-image: url("/assets/images/charge-point-mobile.svg"); }
      .top-mobile .charge-point span {
        white-space: nowrap; }
    .top-mobile .email {
      margin-left: 8px;
      text-align: center; }
      .top-mobile .email a {
        color: #fff;
        text-decoration: none;
        font-weight: bold; }
      .top-mobile .email p {
        margin: 0 !important;
        line-height: 1.2;
        color: #ababab; }
    .top-mobile hr {
      width: 99%;
      margin: 0;
      margin: 0 auto; }
    .top-mobile .contacts-phones {
      margin-top: 15px;
      margin-bottom: 170px; }
      .top-mobile .contacts-phones a {
        font-size: 16px;
        font-weight: bold;
        color: #fff; }
      .top-mobile .contacts-phones p {
        margin: 0 !important;
        font-size: 14px;
        color: #ababab; }
    .top-mobile-header {
      position: fixed;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px 15px;
      background-color: #3d3d3d;
      z-index: 99; }
    .top-mobile-menu {
      position: absolute;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      padding: 0 15px;
      z-index: 99; }
      .top-mobile-menu.active {
        display: block;
        background-color: #3d3d3d;
        height: 100vh;
        overflow: hidden; }
      .top-mobile-menu .wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        margin-top: 10px; }
        .top-mobile-menu .wrapper .menu-wrapper-mobile {
          margin-bottom: 5px; }
          .top-mobile-menu .wrapper .menu-wrapper-mobile .menu li {
            margin-bottom: 10px;
            font-size: 16px;
            color: #fff; }
            .top-mobile-menu .wrapper .menu-wrapper-mobile .menu li a {
              font-size: 16px;
              color: #fff; }
          .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li {
            margin-bottom: 10px;
            font-size: 16px;
            color: #fff; }
            .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li > ul {
              margin-top: 10px;
              margin-left: 15px; }
              .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li > ul > li > a {
                display: inline-block;
                font-weight: bold; }
              .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li > ul > li > ul {
                margin-top: 10px;
                margin-left: 15px; }
            .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li.parent > a {
              position: relative;
              display: inline-block;
              padding-right: 15px; }
              .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li.parent > a::after {
                position: absolute;
                content: '';
                top: 4px;
                right: 0;
                display: block;
                width: 6px;
                height: 6px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                transform: rotate(135deg); }
            .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li.parent.hide ul {
              display: none; }
            .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li.parent.show {
              display: block; }
              .top-mobile-menu .wrapper .menu-wrapper-mobile .menu > li.parent.show > a::after {
                top: 9px;
                transform: rotate(-45deg); }

#masthead {
  height: 268px;
  padding: 0;
  position: relative; }

/* =Main menu
--------------------------------------------------------------------*/
/*Submenu*/
.leftMenu {
  padding: 0 18px 17px 13px;
  border-left: solid 1px #fafafa;
  border-right: solid 1px #fafafa;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #f4f4f4;
  z-index: 1;
  box-sizing: border-box;
  margin-bottom: 20px; }

.leftMenu__h3 {
  color: #3d3d3d;
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 25px 0 8px 12px;
  line-height: 22px;
  text-align: left; }

.leftMenu .leftMenu__h3 a,
.leftMenu ul li a {
  text-decoration: none;
  color: #3d3d3d;
  text-align: left;
  margin: auto; }

.leftMenu a {
  text-align: right;
  display: block;
  margin: 2px 0 2px 5px; }

/* =Left-Right wrapper
--------------------------------------------------------------------*/
#lr-wrap {
  height: 100%;
  padding-bottom: 30px; }

#left ul,
#main ul.arrow {
  list-style: none;
  margin: 0;
  padding: 0; }

#left ul li,
#main ul.arrow li {
  background: transparent url("/assets/i/arrow.gif") 0 2px no-repeat;
  padding: 0 0 0 13px;
  margin: 5px 0; }

#cbot,
#cbot div.blank1BM {
  position: relative;
  width: 100%;
  z-index: 2;
  overflow: visible; }

#left ul.category-module li a.active,
#left ul.category-module li a.active:link,
#left ul.category-module li a.active:visited,
#left ul.category-module li a.active:hover,
#left ul.category-module li a.active:active {
  text-decoration: none;
  color: #333; }

/* =Left
--------------------------------------------------------------------*/
#left {
  float: left;
  position: relative;
  width: 238px;
  margin: 0 0 47px 0;
  box-sizing: border-box; }

#ct {
  position: relative;
  z-index: 250; }

#cb {
  position: relative;
  z-index: 250; }

/* =Main
--------------------------------------------------------------------*/
#main {
  height: 1%;
  position: relative;
  width: 670px;
  float: left;
  overflow: visible;
  z-index: 2;
  padding: 0 20px; }
  #main.bg-main {
    width: 100%;
    padding: 0; }
    @media (max-width: 979px) {
      #main.bg-main {
        padding: 0 20px;
        box-sizing: border-box; } }
    @media (max-width: 510px) {
      #main.bg-main {
        padding: 0 15px; } }

.products #lr-wrap #main,
#main.wide {
  width: 100%; }

/* =Right
--------------------------------------------------------------------*/
#right {
  float: right;
  position: relative;
  width: 257px;
  height: 1%;
  z-index: 1; }

/* =Content
--------------------------------------------------------------------*/
#main ul {
  list-style: none;
  margin: 5px 0 5px 20px;
  padding: 0;
  height: 1%; }

#main ul li {
  display: block;
  /*width: 100%;
	height: 100%;*/
  padding-left: 11px;
  background: transparent url("/assets/i/bullet-01.gif") 0 4px no-repeat;
  margin: 5px 0; }

#main ol {
  margin: 5px 0 5px 0;
  padding: 0 0 0 45px; }

#main ol li {
  padding-left: 0px;
  margin: 5px 0; }

#main img {
  padding: 3px;
  border: solid 1px #d1d1d1;
  max-width: 100%;
  box-sizing: border-box; }

#main #cbot img {
  padding: 0;
  border: 0; }

#main a img,
#main a:link img,
#main a:visited img,
#main a:hover img,
#main a:active img {
  padding: 3px;
  border: solid 1px #d1d1d1;
  background: #fff; }

#main a:hover img {
  border: solid 1px #999; }

#main .noframe {
  padding: 0;
  background: transparent;
  border: none; }

#main .mail {
  background: transparent url("/assets/i/mail.jpg") 0 0 no-repeat;
  padding: 0 0 0 22px; }

#main .adress {
  background: transparent url("/assets/i/home.jpg") 0 0 no-repeat;
  padding: 0 0 0 22px; }

#main .phone li {
  background: transparent url("/assets/i/phone.jpg") 0 0 no-repeat;
  padding: 0 0 0 22px; }

#main div.jicons-icons {
  display: none; }

.nobg {
  background: none !important;
  padding-left: 0 !important; }

.sign {
  text-align: right;
  font-style: italic; }

.sign-mid {
  text-align: center;
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  margin: 10px 0; }

.hint {
  color: #999;
  font-size: 9px; }

.products table td,
#main.wide table td {
  vertical-align: top;
  padding: 0 7px;
  font-size: 9px;
  width: 275px; }

.products table td.art-main,
#main.wide table td.art-main {
  font-size: 11px;
  width: 427px;
  max-width: 427px; }

#main #cbot table.map-table {
  width: 100%; }

#main #cbot table.map-table td {
  padding-bottom: 10px; }

#main #cbot table.map-table td.art-main {
  width: auto; }

#main #cbot table.map-table td.art-right {
  font-size: 11px;
  width: 200px;
  min-width: 200px;
  max-width: 200px; }

table.params {
  width: 100%;
  border-collapse: collapse;
  margin: 7px auto;
  padding: 0; }

table.params tr:first-child {
  font-weight: bold; }

table.params td {
  border: solid 1px #ccc;
  max-width: 105px;
  padding: 2px 4px;
  font-size: 14px;
  text-align: center;
  vertical-align: middle; }

table.params td:first-child {
  text-align: left; }

/* =Yandex map
--------------------------------------------------------------------*/
#main .ymap img,
#main .ymap a img {
  border: none;
  padding: 0; }

#main .ymap ul {
  margin: auto;
  padding: auto; }

#main .ymap ul li {
  background: none;
  padding: auto;
  margin: auto; }

#main .ymap table td {
  padding: 0 !important;
  font-size: 12px; }

#main .ymap .YMaps-placemark .YMaps-b-balloon-frame td {
  width: auto; }

/* =Partners list
--------------------------------------------------------------------*/
#main ul.clients-list {
  list-style: none;
  margin: 5px 0 5px 5px;
  padding: 0;
  height: 1%; }

#main ul.clients-list li {
  display: block;
  height: 70px;
  width: 100%;
  background: none;
  border-bottom: solid 1px #eee; }

#main ul.clients-list li:last-child {
  border: none;
  margin: 10px 0 1px 0; }

#main ul.clients-list li img {
  padding: 0;
  border: none;
  vertical-align: middle;
  float: right;
  width: 120px;
  height: 70px; }

#main ul.clients-list li span {
  padding: 0 10px 0;
  color: #484848;
  font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  float: left;
  display: block;
  width: 300px;
  text-align: right;
  padding: 10px 0 0 0; }

/* =Gallery
--------------------------------------------------------------------*/
.gallery {
  padding: 0 0 0 4px; }

.gallery a,
.gallery a:link,
.gallery a:visited,
.gallery a:hover,
.gallery a:active {
  display: block;
  float: left;
  margin: 0 8px 6px 8px; }

.gallery a:hover img {
  border: solid 1px #d4d4d4; }

/* =News
--------------------------------------------------------------------*/
a.allnews,
a.allnews:link,
a.allnews:visited,
a.allnews:hover,
a.allnews:active {
  display: block;
  text-align: right;
  text-transform: uppercase;
  margin: 5px 5px 20px 5px;
  color: #0081b9;
  text-decoration: none; }

a.allnews:hover {
  color: #006699; }

.newsflash {
  margin: 0 5px 5px 5px;
  font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif; }

.newsflash .newsflash-item {
  border-bottom: solid 1px #e7e7e7; }

.newsflash h5.newsflash-title {
  width: 40px;
  height: 100%;
  float: left;
  text-align: center;
  font-size: 10px;
  color: #666;
  margin: 5px 0;
  padding: 0;
  background: none; }

.newsflash a,
.newsflash a:link,
.newsflash a:visited,
.newsflash a:hover,
.newsflash a:active,
.newsflash p {
  font-size: 11px;
  color: #0081b9;
  width: 207px;
  float: right;
  text-decoration: none;
  padding: 4px 0; }

.newsflash a:hover,
.newsflash a:hover p {
  color: #006699; }

/*show-all*/
.moduletable-show-all-news {
  margin-top: 10px; }

.moduletable-show-all-news a,
.moduletable-show-all-news a:link,
.moduletable-show-all-news a:visited,
.moduletable-show-all-news a:hover,
.moduletable-show-all-news a:active {
  padding-left: 20px;
  text-decoration: none;
  font-weight: bold;
  color: #17A6C4; }

.moduletable-show-all-news a:hover {
  color: #43b9d5; }

/*blog*/
#main .blog .item {
  padding: 0 10px; }

#main .blog .item h3 {
  font-size: 11px;
  color: #fff;
  background: #0e9eda;
  display: block;
  position: relative;
  height: 14px;
  line-height: 14px;
  width: 60px;
  text-align: center;
  margin: 10px 0 5px 0;
  padding: 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0; }

#main .blog .item h3 a,
#main .blog .item h3 a:link,
#main .blog .item h3 a:visited,
#main .blog .item h3 a:hover,
#main .blog .item h3 a:active {
  text-decoration: none;
  color: #fff; }

#main .blog .item h3 a:hover,
.newsflash h3 a:hover {
  color: #eee; }

#main .blog p {
  font-size: 11px;
  margin: 10px; }

.row-separator {
  clear: both;
  display: block;
  margin: 5px 10px;
  border-bottom: solid 1px #e7e7e7; }

#main .blog p.readmore {
  text-align: right; }

/* =Bottom
--------------------------------------------------------------------*/
#bottom {
  clear: both; }

/* =jClasses
--------------------------------------------------------------------*/
.contentpaneopen,
.contentpaneopen tbody,
.contentheading {
  padding: 0;
  margin: 0;
  border: 0; }

div.contentpaneopen h3 {
  border: dotted 0px #94a0a8;
  border-bottom-width: 0; }

.maincontent .article_seperator {
  display: none; }

/* =Messages
--------------------------------------------------------------------*/
.msg img {
  float: left;
  border: solid 1px #ddd;
  margin: 0 10px 0 0; }

.underconst {
  background: url("/assets/i/icon-underconst-01.jpg") transparent 0 0 no-repeat;
  padding-left: 80px;
  height: 70px; }

.msg span {
  padding-top: 10px;
  line-height: 16px;
  float: left;
  height: 70px; }

/* =Search
--------------------------------------------------------------------*/
.search {
  position: absolute;
  right: 10px;
  top: 10px; }

.search .inputbox {
  border: solid 1px #abadb3;
  background: #f4f4f4;
  color: #444;
  margin: 0 2px; }

.search .inputbox:focus,
.search .inputbox:hover {
  border: solid 1px #abadb3;
  background: #fff; }

.search .button {
  height: 21px;
  width: 61px;
  color: #fff;
  font-weight: bold;
  background: url("/assets/images/search-btn-01.png") transparent 0 0 no-repeat;
  border: 0;
  margin: 0 2px; }

.search .button:focus,
.search .button:hover {
  background-position: 0 100%;
  border: 0; }

.search .button:hover {
  cursor: pointer; }

* html .search .button {
  background: url("/assets/images/search-btn-01.jpg") transparent 0 0 no-repeat; }

/* =Poll
--------------------------------------------------------------------*/
.moduletable-poll {
  margin: 20px 0; }

table.pollstableborder-poll {
  margin: 6px 0 6px 10px; }

table.pollstableborder-poll tr {
  height: 20px; }

.poll-buttons input {
  width: 100px; }

.pollstableborder {
  border: solid 1px #c6d2d3 !important;
  padding: 10px !important;
  background: #fff; }

* html table.pollstableborder {
  padding: 2px !important; }

.sectiontableheader {
  height: 30px;
  margin-bottom: 10px;
  text-align: center; }

* html .sectiontableheader {
  padding-top: 10px; }

.sectiontableheader span {
  display: block;
  height: 30px;
  line-height: 30px; }

.sectiontableheader img {
  vertical-align: middle; }

.polls-list select {
  width: 274px !important; }

.poll-title td {
  padding: 5px 0 5px 68px;
  text-align: left !important;
  background: url("/assets/images/bullet-01.gif") transparent 61px 11px no-repeat; }

.poll-stats td {
  padding-bottom: 5px;
  border-bottom: solid 1px #e6e5e5;
  /*c6d2d3*/ }

.poll-stats td td {
  padding-bottom: 0;
  border: none; }

.poll-summary {
  text-align: left;
  margin: 0 auto; }

#poll .pollstableborder .poll-stats .polls_color_1 {
  padding: 5px;
  background-color: #8D1B1B;
  border: 0px solid #B22222; }

#poll .pollstableborder .poll-stats .polls_color_2 {
  padding: 5px;
  background-color: #6740E1;
  border: 0px solid #4169E1; }

#poll .pollstableborder .poll-stats .polls_color_3 {
  padding: 5px;
  background-color: #8D8D8D;
  border: 0px solid #D2D2D2; }

#poll .pollstableborder .poll-stats .polls_color_4 {
  padding: 5px;
  background-color: #CC8500;
  border: 0px solid #FFA500; }

#poll .pollstableborder .poll-stats .polls_color_5 {
  padding: 5px;
  background-color: #5B781E;
  border: 0px solid #6B8E23; }

* html #poll .pollstableborder .poll-stats .polls_color_1,
* html #poll .pollstableborder .poll-stats .polls_color_2,
* html #poll .pollstableborder .poll-stats .polls_color_3,
* html #poll .pollstableborder .poll-stats .polls_color_4,
* html #poll .pollstableborder .poll-stats .polls_color_5 {
  padding: 0 1px; }

/* =Slideshow
--------------------------------------------------------------------*/
#show {
  position: relative;
  margin: 0;
  width: 979px;
  height: 390px; }

#show img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  border: none;
  padding: 0;
  opacity: 0; }

#show img.active {
  z-index: 10;
  opacity: 1; }

#show img.last-active {
  z-index: 9; }

/* =Map
--------------------------------------------------------------------*/
.map-contact {
  font-size: 12px;
  color: #ababab; }

#YMapsID {
  margin: 0 0 10px 0; }

#main #YMapsID img {
  padding: 0;
  border: none; }

.blank1BM {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  background: #ffffff; }

.refuelingPoints_region h3 {
  font-size: 14px;
  margin: 5px 0px;
  padding: 5px 12px;
  line-height: 17px;
  border-bottom: solid 1px #eee; }

.regionPoint {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 20px; }

.regionPoint_cont {
  width: inherit;
  min-height: fit-content; }

.regionPoint_column {
  position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  background-color: #fff; }

.regionPoint_address {
  margin: 5px 0px;
  padding-left: 12px;
  display: block; }

.regionPoint_cont h3,
.regionPoint_range h3,
.regionPoint_contacts h3,
.regionPoint_working h3 {
  font-size: 11px;
  margin: 5px 0;
  padding: 3px 12px;
  background: #eee; }

.regionPoint_range p,
.regionPoint_contacts p,
.regionPoint_working p {
  padding-left: 20px; }

.regionPoint_map {
  height: 400px; }

.mt {
  margin-top: 15px; }

/*forms*/
.form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 5px; }

.form .form-group input {
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #dedede;
  height: 30px;
  border-radius: 5px;
  padding-left: 10px;
  box-sizing: border-box; }

.form .form-group select {
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #dedede;
  background-color: #fff;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box; }

.form .form-group textarea {
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #dedede;
  height: 70px;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  resize: none; }
  @media (max-width: 979px) {
    .form .form-group textarea {
      margin-bottom: 20px; } }

.form .form-group select:focus {
  border-color: #80bdff; }

.form .form-group textarea:focus {
  border-color: #80bdff; }

.form .form-group input:focus {
  border-color: #80bdff; }

.form .form-group label {
  font-size: 13px;
  margin-bottom: 10px; }

.form .form-group .invalid-feedback {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #dc3545;
  font-size: 10px; }

.form .form-group input.is-invalid {
  border-color: #dc3545; }

.form .form-group input.is-valid {
  border-color: #28a745; }

.form .form-group select.is-invalid {
  border-color: #dc3545; }

.form .form-group select.is-valid {
  border-color: #28a745; }

.form .form-group button {
  cursor: pointer;
  font-size: 13px;
  background: #e4e4e4;
  background-origin: padding-box, border-box;
  background-clip: padding-box, border-box;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 12px;
  text-decoration: none;
  outline: 0;
  background-image: -webkit-linear-gradient(#f2f2f2, #e4e4e4 50%), -webkit-linear-gradient(#e4e4e4, #ccc);
  background-image: -moz-linear-gradient(#f2f2f2, #e4e4e4 50%), -moz-linear-gradient(#e4e4e4, #ccc);
  background-image: -ms-linear-gradient(#f2f2f2, #e4e4e4 50%), -ms-linear-gradient(#e4e4e4, #ccc); }

.form .form-group button:hover {
  background-image: -webkit-linear-gradient(#fafafa, #ececec 50%), -webkit-linear-gradient(#ececec, #d4d4d4);
  background-image: -moz-linear-gradient(#fafafa, #ececec 50%), -moz-linear-gradient(#ececec, #d4d4d4);
  background-image: -ms-linear-gradient(#fafafa, #ececec 50%), -ms-linear-gradient(#ececec, #d4d4d4); }

.form .form-group button:active {
  position: relative;
  top: 1px;
  text-shadow: 0 -1px #fff;
  box-shadow: inset 0 1px 4px 1px rgba(0, 0, 0, 0.1), inset 0 6px 14px rgba(0, 0, 0, 0.07), 0 1px #fff;
  background-image: -webkit-linear-gradient(#f0f0f0, #e0e0e0 50%), -webkit-linear-gradient(#cfcfcf, #c6c6c6);
  background-image: -moz-linear-gradient(#f0f0f0, #e0e0e0 50%), -moz-linear-gradient(#cfcfcf, #c6c6c6);
  background-image: -ms-linear-gradient(#f0f0f0, #e0e0e0 50%), -ms-linear-gradient(#cfcfcf, #c6c6c6);
  background-image: -o-linear-gradient(#f0f0f0, #e0e0e0 50%), -o-linear-gradient(#cfcfcf, #c6c6c6);
  background-image: linear-gradient(#f0f0f0, #e0e0e0 50%), linear-gradient(#cfcfcf, #c6c6c6); }

.responceWrap {
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }

.responceMsg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  padding: 40px 60px;
  background-color: #fff;
  box-shadow: 0 0 10px #000;
  box-sizing: border-box; }
  @media (max-width: 510px) {
    .responceMsg {
      padding: 30px 40px; } }

.responceMsg p {
  font-size: 18px; }

.responceMsg .form-success-title {
  margin: 0 0 10px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  text-align: center; }

.responceMsg .form-success-subtitle {
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center; }

.responceClose {
  position: absolute;
  right: 10px;
  top: 10px; }

.responceClose img {
  width: 13px;
  cursor: pointer; }

.couple-group {
  display: flex;
  justify-content: space-between; }

.couple-group .form-group {
  width: 48%; }

/*news*/
.newsItem {
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  margin-bottom: 10px; }

.newsItem .newsPublished {
  font-size: 11px;
  color: #fff;
  background: #0e9eda;
  display: block;
  position: relative;
  height: 14px;
  line-height: 14px;
  width: 60px;
  text-align: center;
  margin: 10px 0 5px 0;
  padding: 0;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0; }

.banner {
  display: flex;
  justify-content: center;
  margin-top: 15px; }

/* Вертикальные табы */
#main .tabs ul {
  margin: 0;
  padding: 0; }

#main .tabs img {
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.tabs {
  display: flex; }
  @media (max-width: 767px) {
    .tabs {
      display: none; } }

.tabs-link {
  display: flex;
  flex-direction: column; }

.tabs-container {
  flex-shrink: 0;
  position: relative;
  background: #efefef;
  width: 50%;
  padding: 20px 0 20px 20px;
  float: left;
  z-index: 20;
  box-sizing: border-box; }

.tabs-container .tab {
  position: relative;
  display: block;
  font-size: 18px;
  color: #fff; }
  .tabs-container .tab span {
    color: #0E96D2;
    border-bottom: 1px dashed #0E96D2;
    cursor: pointer;
    user-select: none; }
    .tabs-container .tab span:hover {
      font-weight: bold;
      border-bottom: 1px dashed transparent; }
  .tabs-container .tab:not(:last-child) {
    margin-bottom: 20px; }
  .tabs-container .tab.active {
    padding: 20px 0; }
    .tabs-container .tab.active span {
      position: relative;
      font-weight: bold;
      color: #ffffff;
      border-bottom: 1px dashed transparent;
      z-index: 99;
      cursor: default; }
    .tabs-container .tab.active .tab-info {
      position: relative;
      display: block;
      z-index: 99;
      margin-top: 6px;
      color: #fff; }
      .tabs-container .tab.active .tab-info p {
        color: #fff; }
    .tabs-container .tab.active::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -30px;
      right: 0;
      content: '';
      display: block;
      background-color: #10A3E4;
      border-radius: 10px 0 0 10px; }
  .tabs-container .tab .tab-info {
    display: none; }

.tabs-container label {
  position: relative; }

.tabs-container .tab-pseudo.active {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; }
  .tabs-container .tab-pseudo.active:before, .tabs-container .tab-pseudo.active:after {
    width: 20px;
    height: 50%;
    position: absolute;
    left: 100%;
    content: ""; }
  .tabs-container .tab-pseudo.active:before {
    top: 1px;
    background: linear-gradient(to right top, #10A3E4 50%, transparent 53%); }
  .tabs-container .tab-pseudo.active:after {
    bottom: 1px;
    background: linear-gradient(to right bottom, #10A3E4 50%, transparent 53%); }

.tab-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  margin-top: 15px;
  font-size: 14px !important;
  color: #0E96D2 !important;
  background-color: #fff;
  border-radius: 10px;
  text-decoration: none !important;
  user-select: none; }
  .tab-more:hover {
    font-weight: bold; }

.tab-content {
  position: relative;
  width: calc(100% - 120px);
  float: left;
  box-sizing: border-box;
  z-index: 19;
  display: none; }

.tab-content:after {
  content: "";
  clear: both; }

input[name="tab"] {
  display: none; }

input[name="tab"]:checked + .tab-content {
  display: block;
  animation: slide 0.5s forwards; }

@keyframes slide {
  from {
    display: none;
    opacity: 0; }
  to {
    display: block;
    opacity: 1; } }

.map-wrapper {
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .map-wrapper {
      margin-bottom: 10px; } }
  @media (max-width: 490px) {
    .map-wrapper {
      padding-top: 274px; } }
  @media (max-width: 490px) {
    .map-wrapper #map_container {
      position: absolute;
      top: 0;
      width: 421px !important;
      height: 391px !important;
      zoom: 0.7; } }

.products {
  margin-bottom: 45px; }
  @media (max-width: 979px) {
    .products {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .products {
      margin-bottom: 30px; } }
  @media (max-width: 979px) {
    .products h3 {
      margin-bottom: 14px !important; } }

.product-category-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px; }
  @media (max-width: 979px) {
    .product-category-wrapper {
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .product-category-wrapper {
      margin-top: 33px; } }

.product-category {
  margin-bottom: 30px; }
  @media (max-width: 979px) {
    .product-category {
      margin-bottom: 15px; } }
  .product-category:nth-child(1) {
    order: 2;
    margin-right: 15px;
    margin-bottom: 0; }
    @media (max-width: 510px) {
      .product-category:nth-child(1) {
        margin: 0 auto; } }
  .product-category:nth-child(2) {
    order: 3;
    margin-bottom: 0; }
    @media (max-width: 510px) {
      .product-category:nth-child(2) {
        margin: 0 auto; } }
  .product-category:nth-child(3) {
    order: 1; }
    @media (max-width: 510px) {
      .product-category:nth-child(3) .product-items {
        margin: 0 auto; } }
    .product-category:nth-child(3) .product-item {
      margin-bottom: 10px; }

.product-category-info .product-category-info-title {
  margin-bottom: 15px;
  font-size: 20px; }
  @media (max-width: 979px) {
    .product-category-info .product-category-info-title {
      margin-bottom: 6px; } }
  @media (max-width: 767px) {
    .product-category-info .product-category-info-title {
      margin-bottom: 10px; } }

.product-category-info .product-category-info-desc {
  margin-bottom: 15px;
  font-size: 16px; }
  @media (max-width: 767px) {
    .product-category-info .product-category-info-desc {
      margin-bottom: 10px; } }

.product-items {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 510px) {
    .product-items {
      width: 290px;
      gap: 0 10px; } }

.product-item {
  width: 150px;
  box-sizing: border-box; }
  @media (max-width: 510px) {
    .product-item {
      width: 140px; } }
  .product-item:not(:last-child) {
    margin-right: 15px; }
    @media (max-width: 510px) {
      .product-item:not(:last-child) {
        margin-right: 0; } }

.product-item-img {
  width: 150px;
  height: 170px;
  margin-bottom: 10px;
  box-sizing: border-box; }
  @media (max-width: 510px) {
    .product-item-img {
      width: 140px; } }
  .product-item-img img {
    padding: 0 !important;
    border: none !important;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media (max-width: 510px) {
      .product-item-img img {
        object-fit: contain; } }

.product-item-info div {
  text-align: center; }

.product-item-info a {
  font-size: 16px;
  color: #0E96D2; }
  .product-item-info a:hover {
    font-weight: bold; }

.equipment {
  margin-bottom: 50px; }
  @media (max-width: 979px) {
    .equipment {
      margin-bottom: 40px; } }
  .equipment .equipment-slider-items {
    display: none; }
    @media (max-width: 767px) {
      .equipment .equipment-slider-items {
        display: block;
        margin: 0 -15px;
        padding: 25px 15px;
        overflow: hidden;
        background-color: #efefef; } }
    .equipment .equipment-slider-items .equipment-slider-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px; }
      @media (max-width: 767px) {
        .equipment .equipment-slider-items .equipment-slider-title {
          margin-bottom: 0; } }
    .equipment .equipment-slider-items .equipment-slider-desc {
      font-size: 16px; }
      @media (max-width: 767px) {
        .equipment .equipment-slider-items .equipment-slider-desc {
          line-height: 1.3; } }
    .equipment .equipment-slider-items .equipment-slider-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      margin: 5px 0 15px;
      border-radius: 10px;
      background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%); }
      .equipment .equipment-slider-items .equipment-slider-link a {
        text-decoration: none;
        color: #fff;
        font-size: 14px; }
    .equipment .equipment-slider-items .equipment-slider-img img {
      padding: 0 !important;
      border: none !important;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .equipment .equipment-slider-items .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 20px;
      border-radius: 5px;
      background: #0E96D2; }

@media (max-width: 979px) {
  .service {
    margin-bottom: 40px; } }

.service h3 {
  font-size: 22px !important;
  margin-bottom: 23px !important; }
  @media (max-width: 767px) {
    .service h3 {
      margin-bottom: 21px !important; } }

.service-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0 30px; }
  @media (max-width: 979px) {
    .service-items {
      margin-bottom: 35px;
      padding: 0 20px; } }
  @media (max-width: 767px) {
    .service-items {
      margin-bottom: 20px;
      justify-content: center;
      gap: 0 15px; } }
  @media (max-width: 375px) {
    .service-items {
      justify-content: center;
      gap: 0 30px;
      width: 260px;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 15px; } }
  .service-items .service-item {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 767px) {
      .service-items .service-item {
        margin-bottom: 15px; } }
    .service-items .service-item img {
      border: none !important;
      padding: 0 !important;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .service-items .service-item .service-item-description {
      display: block;
      font-size: 16px !important;
      line-height: 19px !important;
      text-align: center;
      color: #3D3D3D !important;
      text-decoration: none !important; }
      @media (max-width: 979px) {
        .service-items .service-item .service-item-description {
          flex-direction: column; } }
    .service-items .service-item a {
      margin-bottom: 17px; }
      @media (max-width: 979px) {
        .service-items .service-item a {
          margin-bottom: 13px; } }
      @media (max-width: 767px) {
        .service-items .service-item a {
          margin-bottom: 16px; } }

.service-images {
  max-width: 980px;
  height: 236px; }
  @media (max-width: 979px) {
    .service-images {
      height: 173px; } }
  @media (max-width: 510px) {
    .service-images {
      height: 150px; } }
  .service-images .swiper-slide img {
    padding: 0 !important;
    border: none !important;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.service-introtext p:not(:last-child) {
  margin-bottom: 23px !important; }
  @media (max-width: 979px) {
    .service-introtext p:not(:last-child) {
      margin-bottom: 19px; } }
  @media (max-width: 767px) {
    .service-introtext p:not(:last-child) {
      margin-bottom: 20px !important; } }

.top-banner {
  position: relative;
  display: none;
  padding: 6px 35px 6px 15px;
  background-color: #efefef;
  border-radius: 10px;
  box-sizing: border-box;
  color: #3D3D3D; }
  @media (max-width: 979px) {
    .top-banner {
      margin: 0 20px; } }
  @media (max-width: 767px) {
    .top-banner {
      padding: 10px 25px 10px 13px;
      margin: 20px 15px 0; } }
  .top-banner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px) {
      .top-banner-wrapper {
        align-items: flex-start;
        flex-direction: column; } }
  .top-banner-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 10px;
    height: 10px;
    background: url("/assets/images/banner-close.png") no-repeat center;
    cursor: pointer; }
    .top-banner-close:hover {
      background: url("/assets/images/banner-close-blue.png") no-repeat center; }
    @media (max-width: 767px) {
      .top-banner-close {
        top: 15px;
        transform: unset; } }
  .top-banner-more {
    flex-shrink: 0;
    width: 91px;
    height: 24px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px !important;
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    text-transform: uppercase;
    border-radius: 10px;
    background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
    line-height: 1; }
    .top-banner-more:hover {
      background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%); }
    @media (max-width: 979px) {
      .top-banner-more {
        line-height: 1 !important; } }
    @media (max-width: 767px) {
      .top-banner-more {
        margin-left: 0; } }
  .top-banner-text {
    font-size: 14px;
    margin: 6px 0;
    line-height: 1; }
    @media (max-width: 979px) {
      .top-banner-text {
        line-height: 1 !important; } }
    @media (max-width: 767px) {
      .top-banner-text {
        margin: 0 0 7px 0; } }
    .top-banner-text b {
      letter-spacing: 0.5px; }

.bottom-banner {
  order: 4;
  margin-top: 30px;
  background: linear-gradient(180deg, #0ea8e5 0, #0e96d2 100%); }
  .bottom-banner .top-banner-more {
    background: #ffb800; }
  .bottom-banner .top-banner-text {
    color: #ffffff; }

.contacts-mobile {
  display: none; }
  .contacts-mobile p {
    margin: 0 !important; }
  @media (max-width: 767px) {
    .contacts-mobile {
      display: flex;
      flex-direction: column;
      margin-top: 20px; } }
  .contacts-mobile-phones {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .contacts-mobile-phones .phone p {
      color: #838282; }
      @media (max-width: 767px) {
        .contacts-mobile-phones .phone p {
          font-size: 14px;
          line-height: 1.2; } }
    .contacts-mobile-phones .phone a {
      font-size: 16px;
      font-weight: bold;
      color: #3D3D3D !important;
      text-decoration: none !important;
      letter-spacing: 0.9px; }
  .contacts-mobile-charge {
    display: flex;
    width: 271px;
    padding: 10px 8px;
    border-radius: 10px;
    background-color: #f4f4f4; }
    @media (max-width: 767px) {
      .contacts-mobile-charge {
        padding: 8px; } }
    .contacts-mobile-charge::before {
      content: '';
      display: block;
      width: 14px;
      height: 18px;
      margin-right: 5px;
      background-image: url("/assets/images/charge-point.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
    .contacts-mobile-charge a {
      color: #0F9EDA;
      text-decoration: underline;
      white-space: nowrap; }

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
  font-weight: normal;
  font-size: 11px;
  color: #333;
  position: relative; }
  body.fixed {
    position: fixed; }

body.mceContentBody {
  background: #fff !important; }

li {
  font-size: 14px; }

p {
  padding: 0 !important;
  margin-bottom: 10px !important;
  font-size: 14px;
  line-height: 1.4;
  color: #3D3D3D; }
  @media (max-width: 979px) {
    p {
      line-height: 1.5; } }

div {
  color: #3D3D3D; }

#wrapper {
  max-width: 980px;
  text-align: left;
  margin: 0 auto;
  padding: 0; }

* html body {
  text-align: center; }

img {
  border: 0; }

h1 {
  font-size: 30px;
  font-weight: 400;
  padding: 0;
  margin: 30px 0 22px 0;
  color: #3D3D3D;
  line-height: 1.1; }
  @media (max-width: 767px) {
    h1 {
      margin: 30px 0 20px 0;
      font-size: 24px;
      line-height: 1; } }

h2 {
  margin: 20px 0 15px 0;
  padding: 0;
  font-size: 26px;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 1.1; }
  @media (max-width: 979px) {
    h2 {
      line-height: 1.2; } }

h3 {
  margin: 20px 0 15px 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 1.1; }

h4 {
  color: #3d3d3d;
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
  font-size: 14px;
  margin: 15px 0 10px 0;
  line-height: 17px; }

h5 {
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
  font-size: 14px;
  margin: 5px 0;
  padding: 3px 5px;
  background: #eee;
  clear: both; }

h6 {
  font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
  font-size: 11px;
  font-weight: normal;
  margin: 0;
  padding: 0; }

.left {
  float: left;
  margin: 2px 5px 2px 0; }

.right {
  float: right;
  margin: 2px 0 2px 5px; }

.clear,
.moduletable-clear {
  clear: both; }

#left .lwrap h3 {
  padding: 2px; }
