/* =E-mail-form
--------------------------------------------------------------------*/
.contact-form {
	display: block;
	margin: 10px 0;
	}

.contact-form label {
	float: left;
	width: 150px; /*150px*/
	text-align: right;
	padding-right: 5px;
	padding-top: 2px;
	font-weight: bold;
	color: #333;
	}
.contact-form label span {
	font-weight: normal;
	}

.contact-form p input,
.contact-form p button {
	/*margin-right: 30px;*/
	/*margin-right: 186px;*/
	margin-left: 160px;
	width: 120px;
	background: #eee;
	border: solid 1px #000;
	padding: 3px 8px;
	cursor: pointer;
	font-size: 12px;
	}
.contact-form p input:hover,
.contact-form p button:hover {
	background: #fff;
	border: solid 1px #67594c;
	cursor: pointer;
	}
* html .contact-form .submit {
	margin-left: 159px;
	padding: 2px 0;
	}

.contact-form div {
	margin: 4px 0;
	}
.contact-form p {
	clear: both;
	/*text-align: right;*/
	text-align: left;
	padding: 10px 0 0 0;
	}

.contact-form div input {
	/*width: 250px;*/
	width: 350px;
	}
.contact-form div textarea {
	/*width: 250px;*/
	width: 350px;
	height: 150px;
	}

.contact-form div input,
.contact-form div textarea {
	background: #fafafa; /*f6f1dc*/
	border: solid 1px #000;
	font-size: 1em;
	padding: 2px 4px;
	font-family: Tahoma;
	color: #000;
	}
.contact-form div input:hover,
.contact-form div textarea:hover {
	background: #fff;
	border: solid 1px #67594c;
	}
.contact-form div input:focus,
.contact-form div textarea:focus {
	background: #fff;
	border: solid 1px #67594c;
	}

.contact-form div input.invalid,
.contact-form div textarea.invalid {
	border: solid 1px red !important;
	}

.contact-form .submit {
	color: #000;
	}

.contact-form div input[type="checkbox"] {
	width: auto;
	}

.contact-form div.contact-email-copy {
	height: 10px;
	}
.contact-form div.contact-email-copy label {
	width: auto;
	float: right;
	}
.contact-form div.contact-email-copy input {
	width: auto;
	float: right;
	margin-right: 186px;
	}
* html .contact-form div.contact-email-copy input {
	border: none;
	}

.form-required {
	color: #656565;
	text-align: left;
	margin: 5px 0 18px 0;
	}

/* =Fox-form
--------------------------------------------------------------------*/
.fcaptchacontainer div {
	float: left;
	margin: 0 5px;
	}

.fcaptchafieldcontainer #fcaptcha {
	border: 0;
	padding: 0;
	}

.fcaptchacontainer #reloadbtn {
	width: 16px;
	height: 16px;
	border: 0;
	padding: 0;
	}

.fcaptchacontainer .fcaptchainputcontainer input {
	width: 190px;
	}

/* =Subscription
--------------------------------------------------------------------*/
.modns {
	clear: both;
	}

.subscribe span,
.modns span {
	display: block;
	text-align: center;
	padding: 0 10px
	}

#right form {
	width: 217px;
	margin: 5px;
	height: 89px;
	background: #f2f2f2 url('/assets/i/mailback.gif') 0 0 no-repeat;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	color: #898989;
	padding: 16px 13px 10px 20px;
	}

#right form h3 {
	font-weight: normal;
	color: #3e3e3e;
	padding: 0;
	}

#right form #send {
	float: right;
	width: 80px;
	height: 28px;
	font-size: 11px;
	text-align: center;
	background: transparent url('/assets/i/mail_btn.gif') 0 0 no-repeat;
	border: 0;
	padding: 0 0 4px 3px;
	margin: 0;
	color: #fff;
	cursor: pointer;
	}

#right form #send:hover {
	background-position: bottom;
	}

#right form #field {
	width: 125px;
	margin: 3px 0;
	background: #fafafa;
	border: solid 1px #999;
	font-size: 1em;
	padding: 4px 4px;
	font-family: Tahoma;
	color: #333
	}
#right form #field:hover,
#right form #field:focus {
	background: #fff;
	border: solid 1px #333;
	}

// feedback form
.feedback-form {
	order: 3;
	margin-top: 50px;
    padding: 40px;
    background-color: #efefef;
    color: #3D3D3D;
    box-sizing: border-box;
	@media (max-width:979px) {
		padding: 24px 15px 30px;
	}
	@media (max-width:510px) {
		margin: 0;
		padding: 20px 15px 25px;
	}
	form {
        margin-top: 20px;
		@media (max-width:979px) {
			margin-top: 14px;
		}
		@media (max-width:767px) {
			margin-top: 18px;
		}
        .form-group {
			margin-bottom: 0 !important;
			@media (max-width:979px) {
				width: 290px;
				margin: 0 auto;
			}
			@media (max-width:425px) {
				width: 100%;
			}
            input {
                width: 290px;
                height: 45px;
				margin-bottom: 10px !important;
				@media (max-width:425px) {
					width: 100%;
				}
            }
            input,textarea {
				padding-left: 20px !important;
                color: #3D3D3D;
                font-size: 14px;
                font-family: Arial, Helvetica, sans-serif;
            }
			textarea {
				height: 110px;
			}
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 45px;
                margin: 0 auto;
                font-size: 16px;
                color: #fff;
                background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
                border-radius: 10px;
				&:hover {
					background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%);
				}
            }
            &-wrapper {
                display: flex;
                justify-content: space-between;
				@media (max-width:979px) {
					width: 290px;
					flex-direction: column;
					margin: 0 auto;
				}
				@media (max-width:425px) {
					width: 100%;
				}
            }
        }
    }
}
.feedback-form-title {
	margin-bottom: 8px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
	@media (max-width:979px) {
		margin-bottom: 3px;
	}
}
.feedback-form-desc {
    font-size: 14px;
    text-align: center;
	letter-spacing: 0.4px;
	@media (max-width:767px) {
		line-height: 1.2 !important;
	}
}

// popup-main-form
.popup-main-form {
	display: none;
    position: fixed;
    top: 0px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
	color: #3d3d3d;
	&-title {
		margin: 0 0 10px 0;
		font-size: 24px;
		font-weight: bold;
		text-align: center;
	}
	&-desc {
		margin: 0 0 20px 0;
		font-size: 14px;
	}
	&-content{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		max-width: 500px;
		padding: 40px 60px;
		background-color: #fff;
		box-shadow: 0px 0px 10px #000;
		box-sizing: border-box;
		form {
			.form-group {
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 200px;
					height: 45px;
					margin: 0 auto;
					font-size: 16px;
					color: #fff;
					background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
					border-radius: 10px;
				}
				input {
					height: 45px;
				}
				input,textarea {
					color: #3D3D3D;
					font-size: 14px;
					font-family: Arial, Helvetica, sans-serif;
				}
			}
		}
		&-success {
			display: none;
			.popup-main-form-desc {
				margin-bottom: 0;
				text-align: center;
			}
		}
	}
	&-close {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 14px;
		height: 14px;
		background: url("/assets/images/close_form.svg") no-repeat center center;
		background-size: contain;
		cursor: pointer;
		&:hover {
			background: url('/assets/images/close_form_blue.png') no-repeat center;
		}
	}
}