.footer {
    color: #3d3d3d;
    position: relative;
    display: flex;
    padding: 30px 5px 15px;
    border-top: 1px solid #efefef;
    @media (max-width:979px) {
        padding: 28px 20px 15px;
    }
    @media (max-width:767px) {
        flex-direction: column;
        padding: 30px 15px 120px;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .footer-left {
        flex-shrink: 0;
        width: 180px;
        margin-right: 73px;
        @media (max-width:979px) {
            // margin-right: 32px;
        }
        @media (max-width:767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 25px;
        }
        .logo {
            margin-bottom: 5px;
            @media (max-width:979px) {
                margin-bottom: 0;
            }
        }
        .footer-copy {
            font-size: 14px;
        }
    }
    .footer-right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width:979px) {
            flex-direction: column;
        }
        .footer-nav {
            display: flex;
            flex-direction: column;
            &-wrapper {
                display: flex;
                justify-content: space-between;
                @media (max-width:979px) {
                    margin-bottom: 20px;
                }
                @media (max-width:767px) {
                    flex-direction: column;
                    margin-bottom: 25px;
                }
            }
            .footer-nav-title {
                font-size: 16px;
                margin: 0 0 6px 0;
                @media (max-width:979px) {
                    margin: 0;
                }
            }
            .footer-nav-items {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    margin-bottom: 7px;
                    @media (max-width:979px) {
                        margin-bottom: 3px;
                    }
                    a {
                        font-size: 14px;
                        color: #0E96D2;
                    }
                }
            }
            .footer-nav-products{
                flex-grow: 1;
                .footer-nav-items {
                    column-count: 2;
                    column-gap: 40px;
                    @media (max-width:979px) {
                        column-gap: unset;
                    }
                }
            }
            .footer-nav-services {
                margin-left: 22%;
                @media (max-width:979px) {
                    margin-left: 12%;
                    margin-right: 16%;
                }
                @media (max-width:767px) {
                    margin-top: 20px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        .footer-contacts {
            flex-shrink: 0;
            margin-left: 60px;
            margin-right: 15px;
            @media (max-width:979px) {
                margin-left: 0;
            }
            @media (max-width:767px) {
                margin-right: 0;
            }
            .footer-contacts-wrap {
                @media (max-width:979px) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                @media (max-width:767px) {
                    justify-content: flex-start;
                }
                .footer-contacts-item {
                    flex-shrink: 0;
                    margin-bottom: 3px;
                    @media (max-width:979px) {
                        margin-bottom: 0;
                    }
                    @media (max-width:767px) {
                        &:not(:last-child) {
                            margin-right: 20px;
                        }
                    }
                    @media (max-width:425px) {
                        &:nth-child(2) {
                            margin-right: 0;
                        }
                    }
                    p {
                        margin: 0 !important;
                        color: #838282;
                        font-size: 14px;
                    }
                    a{
                        font-size: 14px;
                        font-weight: bold;
                        color: #3d3d3d;
                        text-decoration: none;
                        letter-spacing: 0.9px;
                    }
                }
            }
            .requestPopup {
                width: 145px;
                height: 40px;
                margin-top: 10px;
                color: #fff;
                background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
                &:hover {
					background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%);
				}
                @media (max-width:979px) {
                    margin-top: 5px;
                    margin-bottom: 18px;
                }
                @media (max-width:767px) {
                    margin-bottom: 29px;
                }
            }
        }
    }
    .footer-address {
        &-mobile {
            display: none;
            @media (max-width:979px) {
                display: block;
            }
            .charge-point {
                width: 273px;
                margin-top: 15px;
                margin-bottom: 0;
                @media (max-width:979px) {
                    margin-bottom: 0;
                    margin-top: 7px;
                }
                a {
                    white-space: nowrap;
                }
            }
        }
        .charge-point {
            width: 273px;
            margin-top: 15px;
            margin-bottom: 0;
            @media (max-width:979px) {
                margin-bottom: 0;
                margin-top: 7px;
            }
            span {
                white-space: nowrap;
            }
        }
        p {
            font-size: 14px;
        }
        @media (max-width:979px) {
            display: none;
        }
    }
    .footer-support {
        position: absolute;
        top: 120px;
        left: 0;
        @media (max-width:979px) {
            left: 20px;
        }
        @media (max-width:767px) {
            top: unset;
            left: 15px;
            bottom: 15px;
        }
        a {
            display: block;
        }
        p {
            font-size: 14px;
        }
    }
}