.main-menu-wrapper {
    @media screen and (max-width: 979px) {
        padding: 0 20px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li {
        line-height: 1.1;
    }
    a {
        font-size: 14px !important;
        text-decoration: none !important;
        color: #fff !important;
        line-height: 1.1;
        @media screen and (max-width: 979px) {
            font-size: 13px !important;
        }
    }
}
.main-menu-top {
    .parent-menu {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 49px;
        background-color: #373737;
        border-radius: 10px;
        &-item {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 3%;
            &.active {
                border-radius: 10px;
                background-color: #12A4DE;
                transform: scaleY(1.1);
            }
            ul {
                display: none;
            }
            &:not(.active) {
                a {
                    transition: text-shadow 0.1s;
                    &::after {
                        display:block;
                        content:attr(title);
                        font-weight:bold;
                        height:1px;
                        color:transparent;
                        overflow:hidden;
                        visibility:hidden;
                        margin-bottom:-1px;
                    }
                    &:hover {
                        text-shadow: 0 0 .9px #fff, 0 0 .9px #fff;
                    }
                }
            }
        }
        &-list {
            &.active {
                position: relative;
                border-radius: 10px 10px 0 0;
                background-color: #12A4DE;
                transform: scaleY(1.1);
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    height: 10px;
                    width: 20px;
                    bottom: 1px;
                }
                &::after {
                    right: -20px;
                    border-radius: 0 0 0 10px;
                    box-shadow: -10px 1px 0 0 #12A4DE;
                }
                &::before {
                    left: -20px;
                    border-radius: 0 0 10px 0;
                    box-shadow: 10px 1px 0 0 #12A4DE;
                }
            }
        }
    }
}
.main-menu-sub {
    .parent-menu {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 15px 20px;
        background-color: #12A4DE;
        border-radius: 0 0 10px 10px;
        gap: 10px 0;
        & > li {
            &:not(.parent-menu-list) {
                &:hover {
                    a {
                        text-decoration: underline !important;
                    }
                }
            }

            display: flex;
            & > a {
                white-space: nowrap;
            }
        }
        .child-menu {
            margin-left: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px 0;
            li {
                &.active {
                    a {
                        font-weight: bold;
                    }
                }
                &.first {
                    margin-right: 15px;
                    & + li.last {
                        padding-left: 15px;
                        border-left: 1px solid #fff;
                    }
                }
                &:not(.first):not(.last) {
                    & + li {
                        border-left: none;
                        padding-left: 0;
                    }
                    margin-right: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-right: 1px solid #fff;
                    border-left: 1px solid #fff;
                }
                &:hover {
                    a {
                        text-decoration: underline !important;
                    }
                }
            }
        }
        & > li {
            // &.active {
            //     a {
            //         font-weight: bold;
            //     }
            // }
            &.first {
                margin-right: 18px;
            }
            &:not(.first):not(.last) {
                & + li {
                    border-left: none;
                    padding-left: 0;
                }
                margin-right: 18px;
                padding-left: 18px;
                padding-right: 18px;
                border-right: 1px solid #fff;
                border-left: 1px solid #fff;
            }
        }
        &-list {
            width: 100%;
            margin-right: 0 !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            border-right: none !important;
            border-left: none !important;
            & > a {
                font-weight: bold;
            }
        }
        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 10px;
            width: 20px;
        }
        &::after {
            top: -15px;
            right: -4px;
            border-radius: 0 0 0 10px;
            box-shadow: -10px 1px 0 0 #12A4DE;
            transform: rotate(-90deg);
        }
        &::before {
            top: -15px;
            left: -5px;
            border-radius: 0 0 10px 0;
            box-shadow: 12px 0px 0 0 #12a4de;
            transform: rotate(90deg);
        }
    }
}