@import "/var/www/samara-argus/assets/sass/components/footer.scss";
@import "/var/www/samara-argus/assets/sass/components/form.scss";
@import "/var/www/samara-argus/assets/sass/components/main-menu.scss";
@import "/var/www/samara-argus/assets/sass/components/map.scss";
@import "/var/www/samara-argus/assets/sass/components/prettyPhoto.scss";
@import "/var/www/samara-argus/assets/sass/components/template.scss";


body {
	margin: 0;
	padding: 0;
	// background: #fff url('/assets/i/bodyback.gif') 0 0 repeat-x;
	font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
	font-weight: normal;
	font-size: 11px;
	color: #333;
	position: relative;
	&.fixed {
		position: fixed;
	}
}

body.mceContentBody {
	background: #fff !important;
}

li {
	font-size: 14px;
}

p {
	padding: 0 !important;
	margin-bottom: 10px !important;
	font-size: 14px;
	line-height: 1.4;
	color: #3D3D3D;
	@media (max-width:979px) {
		line-height: 1.5;
	}
}
div {
	color: #3D3D3D;
}

#wrapper {
	max-width: 980px;
	text-align: left;
	margin: 0 auto;
	padding: 0;
}

* html body {
	text-align: center;
}

img {
	border: 0;
}

h1 {
	font-size: 30px;
	font-weight: 400;
	padding: 0;
	margin: 30px 0 22px 0;
	color: #3D3D3D;
	line-height: 1.1;
	@media (max-width:767px) {
		margin: 30px 0 20px 0;
		font-size: 24px;
		line-height: 1;
	}
}
h2 {
	margin: 20px 0 15px 0;
	padding: 0;
	font-size: 26px;
	font-weight: 400;
	color: #3D3D3D;
	line-height: 1.1;
	@media (max-width:979px) {
		line-height: 1.2;
	}
}
h3 {
	margin: 20px 0 15px 0;
	padding: 0;
	font-size: 20px;
	font-weight: 400;
	color: #3D3D3D;
	line-height: 1.1;
}

h4 {
	color: #3d3d3d;
	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
	font-size: 14px;
	margin: 15px 0 10px 0;
	// margin: 5px 7px;
	// padding: 3px;
	line-height: 17px;
	// border-bottom: solid 1px #eee;
}

h5 {
	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
	font-size: 14px;
	margin: 5px 0;
	padding: 3px 5px;
	background: #eee;
	clear: both;
}

// h1 {
// 	color: #3d3d3d;
// 	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
// 	font-size: 18px; /*1.5em*/
// 	font-weight: bold;
// 	margin: 0;
// 	padding: 25px 0 8px 12px;
// 	line-height: 22px; /*1.2em*/
// }

// h2 {
// 	color: #3d3d3d;
// 	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
// 	font-size: 18px; /*1.5em*/
// 	font-weight: bold;
// 	margin: 0;
// 	padding: 25px 0 8px 12px;
// 	line-height: 22px; /*1.2em*/
// }

// h3 {
// 	color: #3d3d3d;
// 	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
// 	font-size: 16px; /*1.5em*/
// 	font-weight: bold;
// 	margin: 0;
// 	padding: 25px 0 8px 12px;
// 	line-height: 22px; /*1.2em*/
// }

// h4 {
// 	color: #3d3d3d;
// 	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
// 	font-size: 14px;
// 	margin: 5px 7px;
// 	padding: 3px;
// 	line-height: 17px;
// 	border-bottom: solid 1px #eee;
// }

// h5 {
// 	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
// 	font-size: 11px;
// 	margin: 5px 0;
// 	padding: 3px 5px;
// 	background: #eee;
// 	clear: both;
// }

h6 {
	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
	font-size: 11px;
	font-weight: normal;
	margin: 0;
	padding: 0;
}

.left {
	float: left;
	margin: 2px 5px 2px 0;
}

.right {
	float: right;
	margin: 2px 0 2px 5px;
}

.clear,
.moduletable-clear {
	clear: both;
}

#left .lwrap h3 {
	padding: 2px;
}
