.main-page {
	.bg-main {
		h1 {
			font-size: 30px;
			font-weight: 400;
			padding: 0;
			margin: 30px 0 22px 0;
			color: #3D3D3D;
			line-height: 1.1;
			@media (max-width:767px) {
				margin: 30px 0 20px 0;
				font-size: 24px;
				line-height: 1;
			}
		}
		h2 {
			margin: 0 0 20px 0;
			padding: 0;
			font-size: 26px;
			font-weight: 400;
			color: #3D3D3D;
			line-height: 1.1;
			@media (max-width:979px) {
				line-height: 1.2;
			}
		}
		h3 {
			margin: 0 0 15px 0;
			padding: 0;
			font-size: 20px;
			font-weight: 400;
			color: #3D3D3D;
			line-height: 1.1;
		}
		p {
			padding: 0 !important;
			margin: 0 !important;
			font-size: 16px;
			line-height: 1.4;
			color: #3D3D3D;
			@media (max-width:979px) {
				line-height: 1.5;
			}
		}
		div {
			color: #3D3D3D;
		}
	}
	#lr-wrap {
		margin-bottom: 20px;
		@media (max-width:979px) {
			padding-bottom: 0;
		}
	}
}

.item-page-main {
	margin-bottom: 50px;
	@media (max-width:979px) {
		margin-bottom: 36px;
	}
	@media (max-width:767px) {
		margin-bottom: 40px;
	}
}

/*new left menu*/

.menuProd {
    padding: 0 18px 17px 13px;
    border-left: solid 1px #fafafa;
    border-right: solid 1px #fafafa;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: #f4f4f4;
    z-index: 1;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.menuProd ul li.parent {
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.menuProd ul li.parent a {
    text-decoration: underline;
    color: #0081b9;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 25px 0 8px 12px;
    line-height: 22px;
    display: block;
    font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
}

.menuProd ul li.active a {
    color: #3d3d3d;
    text-decoration: none;
}

.menuProd ul h2 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 25px 0 8px 12px;
    line-height: 22px;
    font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
}

.menuProd ul li.parent a:hover {
    text-decoration: none;
}

.menuProd ul li.parent ul li a {
    color: #0081b9;
    text-decoration: underline;
    font-size: 11px;
    outline: none;
    padding: 0;
    margin: 0;
    font-weight: normal;
    line-height: normal;
}

.menuProd ul li ul li.active a {
    color: #3d3d3d;
    text-decoration: none;
}

.menuProd ul li.parent ul li a:hover {
    text-decoration: none;
}

#breadcrumb ul {
    display: flex;
    margin: 10px 0 0 0 !important;
    padding: 0px 0 5px 0 !important;
    border-bottom: 1px solid #dedede;
}

#breadcrumb ul li {
    background: none !important;
    margin: 0 !important;
    padding: 0px 5px !important;
}

a,
a:link,
a:visited,
a:hover,
a:active {
	color: #0081b9;
	text-decoration: underline;
	font-size: 1em;
	outline: none;
}

a:hover {
	text-decoration: none;
}

/* General */
.charge-point {
	display: flex;
	margin-bottom: 7px;
	padding: 10px;
	border-radius: 10px;
	color: #0F9EDA !important;
	background-color: #f4f4f4;
	font-size: 14px !important;
	&:hover {
		color: #fff !important;
		background-color: #0EA7E4;
		text-decoration: underline !important;
		&::before {
			background-image: url("/assets/images/charge-point-white.svg");
		}
	}
	@media screen and (max-width: 979px) {
		padding: 8px;
	}
	&::before {
		content: '';
		display: block;
		width: 14px;
		height: 18px;
		margin-right: 5px;
		background-image: url("/assets/images/charge-point.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}
.requestPopup {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	width: 150px;
	color: #3d3d3d;
	font-size: 16px;
	background-color: #FFB800;
	border-radius: 10px;
	cursor: pointer;
	user-select: none;
	&:hover {
		background-color: #FFD25E;
	}
}

.top {
	display: flex;
	flex-direction: column;
	width: 100%;
	@media screen and (max-width: 767px) {
		display: none;
	}
	.custom {
		display: flex;
		justify-content: space-between;
		padding: 30px 0 18px 0;
		@media screen and (max-width: 979px) {
			padding: 20px;
		}
	}
	.logo {
		margin-right: 30px;
		@media screen and (max-width: 979px) {
			margin-right: 0;
		}
		a {
			text-decoration: none;
		}
		.logo-link {
			display: block;
			margin: 0 0 10px 0;
			img {
				width: 133px;
				height: 40px;
			}
		}
		.logo-title {
			margin: 0;
			color: #838282;
			line-height: 1.4;
			font-size: 11px;
		}
	}
	.contacts {
		display: flex;
		color: #b0aeae;
		font-size: 11px;
		@media screen and (max-width: 979px) {
			flex-direction: column;
		}
		.contacts-left {
			margin-right: 30px;
			@media screen and (max-width: 979px) {
				margin-right: 0;
			}
		}
		.contacts-right {
			display: flex;
			flex-direction: column;
			@media screen and (max-width: 979px) {
				flex-direction: row;
			}
		}
		.phone {
			&:first-child {
				margin-bottom: 7px;
				@media screen and (max-width: 979px) {
					margin-bottom: 0;
					margin-right: 15px;
				}
			}
			a {
				color: #3d3d3d;
				text-decoration:none;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.9px;
			}
			p {
				margin: 0 !important;
				font-size: 14px;
				line-height: 1.2;
			}
		}
		.email {
			font-size: 14px;
			@media screen and (max-width: 979px) {
				margin-bottom: 5px;
			}
			a {
				color: #3d3d3d;
				text-decoration:none;
				font-weight: bold;
			}
			p {
				margin: 0 !important;
				line-height: 1.2;
			}
		}
		.contacts-desc {
			color: #838282;
		}
	}
	.ya-widget {
		position: relative;
		width: 150px;
		height: 50px;
		margin-bottom: 7px;
		background-color: #f6f6f6;
		pointer-events: all;
	}
	.ya-widget-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		cursor: pointer;
	}

	&-mobile {
		display: none;
		@media screen and (max-width: 767px) {
			display: flex;
		}
		a {
			text-decoration: none;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		.logo {
			margin-right: auto;
			.logo-link {
				display: block;
				width: 90px;
				height: 26px;
				margin: 0;
				background-image: url("/assets/images/logo-mobile.svg");
			}
		}
		.phones {
			position: relative;
			width: 19px;
			height: 19px;
			background-image: url("/assets/images/phone.svg");
			&.active {
				background-image: url("/assets/images/phone-active.svg");
			}
		}
		.tooltip-phones {
			position: absolute;
			top: 35px;
			right: -50px;
			display: none;
			&.active {
				display: flex;
				flex-direction: column;
				// width: 166px;
				// height: 112px;
				padding: 15px;
				background-color: #FFB800;
				border-radius: 10px;
				box-sizing: border-box;
				&::before {
					position: absolute;
					top: -10px;
					right: 50px;
					content: '';
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 10px solid #ffb800;
				}
			}
			.tooltip-phone {
				&:first-child {
					margin-bottom: 10px;
				}
			}
			a {
				font-size: 16px;
				font-weight: bold;
				color: #3d3d3d;
				letter-spacing: 0.9px;
				white-space: nowrap;
			}
			p {
				margin: 0 !important;
				font-size: 14px;
				line-height: 1;
				color: #3d3d3d;
			}
		}
		.burger {
			width: 24px;
			height: 17px;
			margin-left: 30px;
			background-image: url("/assets/images/burger.svg");
			&.active {
				background-image: url("/assets/images/burger-active.svg");
			}
		}
		.contacts {
			display: flex;
			margin-bottom: 20px;
		}
		.requestPopup {
			flex-shrink: 0;
			width: 120px;
			height: 33px;
			font-size: 14px;
		}
		.charge-point {
			width: 271px;
			margin-bottom: 10px;
			padding: 9px;
			background-color: #6c6c6c;
			color: #fff !important;
    		text-decoration: underline !important;
			&::before {
				background-image: url("/assets/images/charge-point-mobile.svg");
			}
			span {
				white-space: nowrap;
			}
		}
		.email {
			margin-left: 8px;
			text-align: center;
			a {
				color: #fff;
				text-decoration:none;
				font-weight: bold;
			}
			p {
				margin: 0 !important;
				line-height: 1.2;
				color: #ababab;
			}
		}
		hr {
			width: 99%;
			margin: 0;
			margin: 0 auto;
		};
		.contacts-phones {
			margin-top: 15px;
			margin-bottom: 170px;
			a {
				font-size: 16px;
				font-weight: bold;
				color: #fff;
			}
			p {
				margin: 0 !important;
				font-size: 14px;
				color: #ababab;
			}
		}

		&-header {
			position: fixed;
			display: flex;
			align-items: center;
			top: 0;
			left: 0;
			right: 0;
			padding: 10px 15px;
			background-color: #3d3d3d;
			z-index: 99;
		}
		&-menu {
			position: absolute;
			top: 45px;
			bottom: 0;
			left: 0;
			right: 0;
			display: none;
			padding: 0 15px;
			z-index: 99;
			&.active {
				display: block;
				background-color: #3d3d3d;
				height: 100vh;
				overflow: hidden;
			}
			.wrapper {
				display: flex;
				flex-direction: column;
				overflow-y: scroll;
				width: 100%;
				height: 100%;
				margin-top: 10px;
				.menu-wrapper-mobile {
					margin-bottom: 5px;
					.menu {
						li {
							margin-bottom: 10px;
							font-size: 16px;
							color: #fff;
							a {
								font-size: 16px;
								color: #fff;
							}
						}
						& > li {
							margin-bottom: 10px;
							font-size: 16px;
							color: #fff;
							& > ul {
								margin-top: 10px;
								margin-left: 15px;
								& > li {
									& > a {
										display: inline-block;
										// margin-bottom: 10px;
										font-weight: bold;
									}
									& > ul {
										margin-top: 10px;
										margin-left: 15px;
									}
								}
							}
							&.parent {
								& > a {
									position: relative;
									display: inline-block;
									padding-right: 15px;
									&::after {
										position: absolute;
										content: '';
										top: 4px;
										right: 0;
										display: block;
										width: 6px;
										height: 6px;
										border-top: 2px solid #fff;
										border-right: 2px solid #fff;
										transform: rotate(135deg);
									}
								}
								&.hide {
									ul {
										display: none;
									}
								}
								&.show {
									display: block;
									& > a::after {
										top: 9px;
										transform: rotate(-45deg);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#masthead {
	height: 268px;
	padding: 0;
	position: relative;
}


/* =Main menu
--------------------------------------------------------------------*/
#mainmenu {
	// height: 90px;
	// width: 100%;
	// position: relative;
	// background: transparent url('/assets/i/menuback.jpg') no-repeat;
	// border-radius: 10px;
	// overflow: hidden;
}

#mainmenu ul {
	// list-style: none;
	// margin: 0;
	// padding: 0;
	// height: 55px;
}
* html #mainmenu ul {
	// display: inline;
}

#mainmenu ul li {
	// float: left;
	// width: calc(100%/7);
	// background: transparent url('/assets/i/selector.gif') 0 0 no-repeat;
}

#mainmenu ul li:first-child {
	// background: none;
}

#mainmenu ul li a:link,
#mainmenu ul li a:visited,
#mainmenu ul li a:hover,
#mainmenu ul li a:active {
	// font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
	// font-size: 14px;
	// color: #fff;
	// text-decoration: none;
	// display: block;
	// height: 55px;
	// text-align: center;
	// line-height: 50px;
	// padding: 0 2px;
	// @media screen and (max-width: 979px) {

	// }
}

#mainmenu ul li a:hover {
	// color: #63d2ff;
}

#mainmenu ul li.active a,
#mainmenu ul li.active a:link,
#mainmenu ul li.active a:visited,
#mainmenu ul li.active a:hover,
#mainmenu ul li.active a:active {
	// color: #fff;
	// background: transparent url('/assets/i/menuactive.gif') 50% 0 no-repeat;
	// border-radius: 10px 10px 0 0;
}

#mainmenu ul li.active:first-child a,
#mainmenu ul li.active:first-child a:link,
#mainmenu ul li.active:first-child a:visited,
#mainmenu ul li.active:first-child a:hover,
#mainmenu ul li.active:first-child a:active {
	// color: #fff;
	// background: transparent url('/assets/i/menuactivefirst.gif') 0 0 no-repeat;
}

#mainmenu ul li.active:last-child a,
#mainmenu ul li.active:last-child a:link,
#mainmenu ul li.active:last-child a:visited,
#mainmenu ul li.active:last-child a:hover,
#mainmenu ul li.active:last-child a:active {
	// color: #fff;
	// background: transparent url('/assets/i/menuactivelast.gif') 100% 0 no-repeat;
}

#mainmenu ul li.parent:last-child ul li a, #mainmenu ul li.parent ul li.active a {
    // width: auto !important;
}

#mainmenu .menu li:not(.active) > ul {
    // display: none !important;
}

/*Submenu*/
#mainmenu ul li.parent ul {
	// list-style: none;
	// margin: 0;
	// padding: 0;
	// height: 35px;
	// width: 100%;
	// position: absolute;
	// top: 55px;
	// left: 0;
	// display: none;
	// padding-left: 15px;
}

#mainmenu ul li.parent ul li {
	// width: auto;
	// background: transparent url('/assets/i/selector2.gif') 0 0 no-repeat;
	// background: transparent url('/assets/images/line-2.png') 0 center no-repeat;
	// float: left;
}

#mainmenu ul li.parent ul li:first-child {
	// background: none;
}

#mainmenu ul li.parent ul li a,
#mainmenu ul li.parent ul li a:link,
#mainmenu ul li.parent ul li a:visited,
#mainmenu ul li.parent ul li a:hover,
#mainmenu ul li.parent ul li a:active {
	// font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
	// font-size: 14px;
	// color: #fff;
	// text-decoration: none;
	// text-transform: lowercase;
	// display: block;
	// height: 35px;
	// width: auto;
	// padding: 0 15px;
	// line-height: 32px;
	// font-weight: normal;
	// background: transparent !important;
	// @media screen and (max-width: 979px) {
	// 	font-size: 13px;
	// 	padding: 0 5px;
	// }
}

#mainmenu ul li.parent ul li a:hover,
#mainmenu ul li.parent ul li.active a {
	// color: #032c3e !important;
}

.leftMenu {
    padding: 0 18px 17px 13px;
    border-left: solid 1px #fafafa;
    border-right: solid 1px #fafafa;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: #f4f4f4;
    z-index: 1;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.leftMenu__h3{
	color: #3d3d3d;
	font-family: Arial, Tahoma, Verdana, Georgia, "Times New Roman", times, "Microsoft Sans Serif", sans-serif;
	font-size: 16px;
	font-weight: bold;
	margin: 0;
	padding: 25px 0 8px 12px;
	line-height: 22px;
	text-align: left;
}

.leftMenu .leftMenu__h3 a,
.leftMenu ul li a {
    text-decoration: none;
    color: #3d3d3d;
    text-align: left;
    margin: auto;
}

.leftMenu a {
    text-align: right;
    display: block;
    margin: 2px 0 2px 5px;
}

/* =Left-Right wrapper
--------------------------------------------------------------------*/
#lr-wrap {
	height: 100%;
	padding-bottom: 30px;
}

#left ul,
#main ul.arrow {
	list-style: none;
	margin: 0;
	padding: 0;
}

#left ul li,
#main ul.arrow li {
	background: transparent url('/assets/i/arrow.gif') 0 2px no-repeat;
	padding: 0 0 0 13px;
	margin: 5px 0;
}

#cbot,
#cbot div.blank1BM {
	position: relative;
	width: 100%;
	z-index: 2;
	overflow: visible;
	}

#left ul.category-module li a.active,
#left ul.category-module li a.active:link,
#left ul.category-module li a.active:visited,
#left ul.category-module li a.active:hover,
#left ul.category-module li a.active:active {
	text-decoration: none;
	color: #333;
	}


/* =Left
--------------------------------------------------------------------*/
#left {
    float: left;
    position: relative;
    width: 238px;
    margin: 0 0 47px 0;
    box-sizing: border-box;
}

#ct {
	position: relative;
	z-index: 250;
}

#cb {
	position: relative;
	z-index: 250;
}

/* =Main
--------------------------------------------------------------------*/
#main {
	height: 1%;
	position: relative;
	width: 670px;
	float: left;
	overflow: visible;
	z-index: 2;
	padding: 0 20px;
	&.bg-main {
		width: 100%;
		padding: 0;
		@media (max-width:979px) {
			padding: 0 20px;
			box-sizing: border-box;
		}
		@media (max-width:510px) {
			padding: 0 15px;
		}
	}
}

.products #lr-wrap #main,
#main.wide {
	// width: 702px;
	width: 100%;
}

/* =Right
--------------------------------------------------------------------*/
#right {
	float: right;
	position: relative;
	width : 257px;
	height: 1%;
	z-index: 1;
}

/* =Content
--------------------------------------------------------------------*/
#main ul {
	list-style: none;
	margin: 5px 0 5px 20px;
	padding: 0;
	height: 1%;
}

#main ul li {
	display: block;
	/*width: 100%;
	height: 100%;*/
	padding-left: 11px;
	background: transparent url('/assets/i/bullet-01.gif') 0 4px no-repeat;
	margin: 5px 0;
	}

#main ol {
	margin: 5px 0 5px 0;
	padding: 0 0 0 45px;
	}

#main ol li {
	padding-left: 0px;
	margin: 5px 0;
	}

#main img {
	padding: 3px;
	border: solid 1px #d1d1d1;
	max-width: 100%;
	box-sizing: border-box;
}

#main #cbot img {
	padding: 0;
	border: 0;
	}

#main a img,
#main a:link img,
#main a:visited img,
#main a:hover img,
#main a:active img {
	padding: 3px;
	border: solid 1px #d1d1d1;
	background: #fff;
	}
#main a:hover img {
	border: solid 1px #999;
	}

#main .noframe {
	padding: 0;
	background: transparent;
	border: none;
	}

#main .mail{
	background: transparent url('/assets/i/mail.jpg') 0 0 no-repeat;
	padding: 0 0 0 22px;
	}

#main .adress {
	background: transparent url('/assets/i/home.jpg') 0 0 no-repeat;
	padding: 0 0 0 22px;
	}

#main .phone li {
	background: transparent url('/assets/i/phone.jpg') 0 0 no-repeat;
	padding: 0 0 0 22px;
	}

#main div.jicons-icons {
	display: none;
	}

.nobg {
	background: none !important;
	padding-left: 0 !important;
	}

.sign {
	text-align: right;
	font-style: italic;
	}
.sign-mid {
	text-align: center;
	font-style: italic;
	font-weight: bold;
	font-size: 13px;
	margin: 10px 0;
	}

.hint {
	color: #999;
	font-size: 9px;
	}

.products table td,
#main.wide table td {
	vertical-align: top;
	padding: 0 7px;
	font-size: 9px;
	width: 275px;
	}

.products table td.art-main,
#main.wide table td.art-main {
	font-size: 11px;
	width: 427px;
	max-width: 427px;
	}

#main #cbot table.map-table {
	width: 100%;
	}
#main #cbot table.map-table td {
	padding-bottom: 10px;
	}
#main #cbot table.map-table td.art-main {
	width: auto;
	}
#main #cbot table.map-table td.art-right {
	font-size: 11px;
	width: 200px;
	min-width: 200px;
	max-width: 200px;
	}

table.params {
	width: 100%;
	border-collapse: collapse;
	margin: 7px auto;
	padding: 0;
	}

table.params tr:first-child {
	font-weight: bold;
	}

table.params td {
	border: solid 1px #ccc;
	max-width: 105px;
	padding: 2px 4px;
	font-size: 14px;
	text-align: center;
	vertical-align: middle;
	}

table.params td:first-child {
	text-align: left;
	}

/* =Yandex map
--------------------------------------------------------------------*/
#main .ymap img,
#main .ymap a img {
	border: none;
	padding: 0;
	}

#main .ymap ul {
	margin: auto;
	padding: auto;
	}
#main .ymap ul li {
	background: none;
	padding: auto;
	margin: auto;
	}
#main .ymap table td {
	padding: 0 !important;
	font-size: 12px;
	}

#main .ymap .YMaps-placemark .YMaps-b-balloon-frame {

	}
#main .ymap .YMaps-placemark .YMaps-b-balloon-frame td {
	width: auto;
	}

/* =Partners list
--------------------------------------------------------------------*/
#main ul.clients-list {
	list-style: none;
	margin: 5px 0 5px 5px;
	padding: 0;
	height: 1%;
	}

#main ul.clients-list li {
	display: block;
	height: 70px;
	width: 100%;
	background: none;
	border-bottom: solid 1px #eee;
	}
#main ul.clients-list li:last-child {
	border: none;
	margin: 10px 0 1px 0;
	}

#main ul.clients-list li img {
	padding: 0;
	border: none;
	vertical-align: middle;
	float: right;
	width: 120px;
	height: 70px;
	}

#main ul.clients-list li span {
	padding: 0 10px 0;
	color: #484848;
	font-family: Arial, Tahoma, Verdana, "Microsoft Sans Serif", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	float: left;
	display: block;
	width: 300px;
	text-align: right;
	padding: 10px 0 0 0;
	}

/* =Gallery
--------------------------------------------------------------------*/
.gallery {
	padding: 0 0 0 4px;
	}
.gallery a,
.gallery a:link,
.gallery a:visited,
.gallery a:hover,
.gallery a:active  {
	display: block;
	float: left;
	margin: 0 8px 6px 8px;
	}
.gallery a:hover img {
	border: solid 1px #d4d4d4;
	}


/* =News
--------------------------------------------------------------------*/

a.allnews,
a.allnews:link,
a.allnews:visited,
a.allnews:hover,
a.allnews:active {
	display: block;
	text-align: right;
	text-transform: uppercase;
	margin: 5px 5px 20px 5px;
	color: #0081b9;
	text-decoration: none;
	}

a.allnews:hover {
	color: #006699;
	}

.newsflash {
	margin: 0 5px 5px 5px;
	font-family: Tahoma, Verdana, Arial, "Microsoft Sans Serif", sans-serif;
	}

.newsflash .newsflash-item {
	border-bottom: solid 1px #e7e7e7;
	}

.newsflash h5.newsflash-title {
	width: 40px;
	height: 100%;
	float: left;
	text-align: center;
	font-size: 10px;
	color: #666;
	margin: 5px 0;
	padding: 0;
	background: none;
	}

.newsflash a,
.newsflash a:link,
.newsflash a:visited,
.newsflash a:hover,
.newsflash a:active,
.newsflash p {
	font-size: 11px;
	color: #0081b9;
	width: 207px;
	float: right;
	text-decoration: none;
	padding: 4px 0;
	}

.newsflash a:hover,
.newsflash a:hover p {
	color: #006699;
	}

/*show-all*/
.moduletable-show-all-news {
	margin-top: 10px;
	}

.moduletable-show-all-news a,
.moduletable-show-all-news a:link,
.moduletable-show-all-news a:visited,
.moduletable-show-all-news a:hover,
.moduletable-show-all-news a:active {
	padding-left: 20px;
	text-decoration: none;
	font-weight: bold;
	color: #17A6C4;
	}
.moduletable-show-all-news a:hover {
	color: #43b9d5;
	}

/*blog*/
#main .blog .item {
	padding: 0 10px;
	}
#main .blog .item h3 {
	font-size: 11px;
	color: #fff;
	background: #0e9eda;
	display: block;
	position: relative;
	height: 14px;
	line-height: 14px;
	width: 60px;
	text-align: center;
	margin: 10px 0 5px 0;
	padding: 0;
	border-radius: 0 5px 5px 0;
	-moz-border-radius: 0 5px 5px 0;
	-webkit-border-radius: 0 5px 5px 0;
	}
#main .blog .item h3 a,
#main .blog .item h3 a:link,
#main .blog .item h3 a:visited,
#main .blog .item h3 a:hover,
#main .blog .item h3 a:active {
	text-decoration: none;
	color: #fff;
	}
#main .blog .item h3 a:hover,
.newsflash h3 a:hover {
	color: #eee;
	}
#main .blog p {
	font-size: 11px;
	margin: 10px;
	}

.row-separator {
	clear: both;
	display: block;
	margin: 5px 10px;
	border-bottom: solid 1px #e7e7e7;
}

#main .blog p.readmore {
	text-align: right;
}

/* =Bottom
--------------------------------------------------------------------*/
#bottom {
	clear: both;
}

/* =jClasses
--------------------------------------------------------------------*/
.contentpaneopen,
.contentpaneopen tbody,
.contentheading {
	padding: 0;
	margin: 0;
	border: 0;
	}

div.contentpaneopen h3 {
	border: dotted 0px #94a0a8;
	border-bottom-width: 0;
	}

.maincontent .article_seperator {
	display: none;
	}

/* =Messages
--------------------------------------------------------------------*/
.msg img {
	float: left;
	border: solid 1px #ddd;
	margin: 0 10px 0 0;
	}

.underconst {
	background: url("/assets/i/icon-underconst-01.jpg") transparent 0 0 no-repeat;
	padding-left: 80px;
	height: 70px;
	}

.msg span {
	padding-top: 10px;
	line-height: 16px;
	float: left;
	height: 70px;
	}

/* =Search
--------------------------------------------------------------------*/
.search {
	position: absolute;
	right: 10px;
	top: 10px;
	}

.search .inputbox {
	border: solid 1px #abadb3;
	background: #f4f4f4;
	color: #444;
	margin: 0 2px;
	}
.search .inputbox:focus,
.search .inputbox:hover {
	border: solid 1px #abadb3;
	background: #fff;
	}

.search .button {
	height: 21px;
	width: 61px;
	color: #fff;
	font-weight: bold;
	background: url("/assets/images/search-btn-01.png") transparent 0 0 no-repeat;
	border: 0;
	margin: 0 2px;
	}
.search .button:focus,
.search .button:hover {
	background-position: 0 100%;
	border: 0;
	}
.search .button:hover {
	cursor: pointer;
	}

* html .search .button {
	background: url("/assets/images/search-btn-01.jpg") transparent 0 0 no-repeat;
	}

/* =Poll
--------------------------------------------------------------------*/
.moduletable-poll {
	margin: 20px 0;
	}

table.pollstableborder-poll {
	margin: 6px 0 6px 10px;
	}

table.pollstableborder-poll tr {
	height: 20px;
	}

.poll-buttons input {
	width: 100px;
	}

.pollstableborder {
	border: solid 1px #c6d2d3 !important;
	padding: 10px !important;
	background: #fff;
	}
* html table.pollstableborder {
	padding: 2px !important;
	}

.sectiontableheader {
	height: 30px;
	margin-bottom: 10px;
	text-align: center;
	}
* html .sectiontableheader {
	padding-top: 10px;
	}
.sectiontableheader span {
	display: block;
	height: 30px;
	line-height: 30px;
	}
.sectiontableheader img {
	vertical-align: middle;
	}

.polls-list select {
	width: 274px !important;
	}

.poll-title td {
	padding: 5px 0 5px 68px;
	text-align: left !important;
	background: url("/assets/images/bullet-01.gif") transparent 61px 11px no-repeat;
	}

.poll-stats td {
	padding-bottom: 5px;
	border-bottom: solid 1px #e6e5e5; /*c6d2d3*/
	}
.poll-stats td td {
	padding-bottom: 0;
	border: none;
	}

.poll-summary {
	text-align: left;
	margin: 0 auto;
}

#poll .pollstableborder .poll-stats .polls_color_1 { padding: 5px; background-color: #8D1B1B; border: 0px solid #B22222; }
#poll .pollstableborder .poll-stats .polls_color_2 { padding: 5px; background-color: #6740E1; border: 0px solid #4169E1; }
#poll .pollstableborder .poll-stats .polls_color_3 { padding: 5px; background-color: #8D8D8D; border: 0px solid #D2D2D2; }
#poll .pollstableborder .poll-stats .polls_color_4 { padding: 5px; background-color: #CC8500; border: 0px solid #FFA500; }
#poll .pollstableborder .poll-stats .polls_color_5 { padding: 5px; background-color: #5B781E; border: 0px solid #6B8E23; }

* html #poll .pollstableborder .poll-stats .polls_color_1,
* html #poll .pollstableborder .poll-stats .polls_color_2,
* html #poll .pollstableborder .poll-stats .polls_color_3,
* html #poll .pollstableborder .poll-stats .polls_color_4,
* html #poll .pollstableborder .poll-stats .polls_color_5 {
	padding: 0 1px;
}

/* =Slideshow
--------------------------------------------------------------------*/
#show {
    position:relative;
	margin: 0;
	width: 979px;
	height: 390px;
}

#show img {
    position:absolute;
    top:0;
    left:0;
    z-index:8;
	border: none;
	padding: 0;
	opacity: 0;
}

#show img.active {
    z-index:10;
	opacity: 1;
}

#show img.last-active {
    z-index:9;
}

/* =Map
--------------------------------------------------------------------*/
.map-contact {
	font-size: 12px;
	color: #ababab;
	}

#YMapsID {
	margin: 0 0 10px 0;
	}

#main #YMapsID img {
	padding: 0;
	border: none;
	}

.blank1BM{
margin-top:0px;
margin-bottom:0px;
margin-left:0px;
overflow:hidden;
padding-left:0px;
padding-right:0px;
padding-top:0px;
padding-bottom:0px;
width:100%;
background:#ffffff;}

.refuelingPoints_region h3 {
    font-size: 14px;
    margin: 5px 0px;
    padding: 5px 12px;
    line-height: 17px;
    border-bottom: solid 1px #eee;
}

.regionPoint {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.regionPoint_cont {
	width: inherit;
	min-height: fit-content;
}

.regionPoint_column {
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
    background-color: #fff;
}

.regionPoint_address {
    margin: 5px 0px;
    padding-left: 12px;
    display: block;
}

.regionPoint_cont h3,
.regionPoint_range h3,
.regionPoint_contacts h3,
.regionPoint_working h3 {
    font-size: 11px;
    margin: 5px 0;
    padding: 3px 12px;
    background: #eee;
}

.regionPoint_range p,
.regionPoint_contacts p,
.regionPoint_working p {
    padding-left: 20px;
}

.regionPoint_map {
    height: 400px;
}

.mt {
    margin-top: 15px;
}

/*forms*/
.form .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 5px;
}

.form .form-group input {
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid #dedede;
    height: 30px;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
}

.form .form-group select {
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid #dedede;
    background-color: #fff;
    height: 30px;
    border-radius: 5px;
    box-sizing: border-box;
}

.form .form-group textarea {
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid #dedede;
    height: 70px;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    resize: none;
	@media (max-width:979px) {
		margin-bottom: 20px;
	}
}

.form .form-group select:focus {
    border-color: #80bdff;
}

.form .form-group textarea:focus {
    border-color: #80bdff;
}

.form .form-group input:focus {
    border-color: #80bdff;
}
.form .form-group label {
    font-size: 13px;
    margin-bottom: 10px;
}

.form .form-group .invalid-feedback {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #dc3545;
    font-size: 10px;
}

.form .form-group input.is-invalid {
    border-color: #dc3545;
}

.form .form-group input.is-valid {
    border-color: #28a745;
}

.form .form-group select.is-invalid {
    border-color: #dc3545;
}

.form .form-group select.is-valid {
    border-color: #28a745;
}

.form .form-group button {
    cursor: pointer;
    font-size: 13px;
    background: #e4e4e4;
    background-origin: padding-box, border-box;
    background-clip: padding-box, border-box;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 5px 12px;
    text-decoration: none;
    outline: 0;
    background-image: -webkit-linear-gradient(#f2f2f2, #e4e4e4 50%), -webkit-linear-gradient(#e4e4e4, #ccc);
    background-image:    -moz-linear-gradient(#f2f2f2, #e4e4e4 50%),    -moz-linear-gradient(#e4e4e4, #ccc);
    background-image:     -ms-linear-gradient(#f2f2f2, #e4e4e4 50%),     -ms-linear-gradient(#e4e4e4, #ccc);
}

.form .form-group button:hover {
    background-image: -webkit-linear-gradient(#fafafa, #ececec 50%), -webkit-linear-gradient(#ececec, #d4d4d4);
    background-image:    -moz-linear-gradient(#fafafa, #ececec 50%),    -moz-linear-gradient(#ececec, #d4d4d4);
    background-image:     -ms-linear-gradient(#fafafa, #ececec 50%),     -ms-linear-gradient(#ececec, #d4d4d4);
}

.form .form-group button:active {
    position: relative;
    top: 1px;
    text-shadow: 0 -1px #fff;
    box-shadow: inset 0 1px 4px 1px rgba(0,0,0,0.1), inset 0 6px 14px rgba(0,0,0,0.07), 0 1px #fff;
    background-image: -webkit-linear-gradient(#f0f0f0, #e0e0e0 50%), -webkit-linear-gradient(#cfcfcf, #c6c6c6);
    background-image:    -moz-linear-gradient(#f0f0f0, #e0e0e0 50%),    -moz-linear-gradient(#cfcfcf, #c6c6c6);
    background-image:     -ms-linear-gradient(#f0f0f0, #e0e0e0 50%),     -ms-linear-gradient(#cfcfcf, #c6c6c6);
    background-image:      -o-linear-gradient(#f0f0f0, #e0e0e0 50%),      -o-linear-gradient(#cfcfcf, #c6c6c6);
    background-image:         linear-gradient(#f0f0f0, #e0e0e0 50%),         linear-gradient(#cfcfcf, #c6c6c6);
}

.responceWrap {
    display: none;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
}
.responceMsg {
    // width: 400px;
    // height: 100px;
    // background-color: #fff;
    // position: absolute;
    // top: 50%;
    // left: calc(50% - 200px);
    // padding: 20px;
    // box-sizing: border-box;
    // border-radius: 5px;
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 500px;
    padding: 40px 60px;
    background-color: #fff;
    box-shadow: 0 0 10px #000;
    box-sizing: border-box;
	@media (max-width:510px) {
		padding: 30px 40px;
	}
}

.responceMsg p {
    font-size: 18px;
}

.responceMsg .form-success-title {
	margin: 0 0 10px 0;
    font-size: 24px;
	font-weight: 700;
	line-height: 1;
	text-align: center;
}

.responceMsg .form-success-subtitle {
	margin: 0 0 20px 0;
    font-size: 14px;
	font-weight: 400;
	line-height: 1;
	text-align: center;
}

.responceClose {
    position: absolute;
    right: 10px;
    top: 10px;
}

.responceClose img{
    width: 13px;
    cursor: pointer;
}

.couple-group {
    display: flex;
    justify-content: space-between;
}

.couple-group .form-group {
    width: 48%;
}

/*news*/
.newsItem {
    border-bottom: 1px solid #dedede;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.newsItem .newsPublished{
    font-size: 11px;
    color: #fff;
    background: #0e9eda;
    display: block;
    position: relative;
    height: 14px;
    line-height: 14px;
    width: 60px;
    text-align: center;
    margin: 10px 0 5px 0;
    padding: 0;
    border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
}

.banner {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}


/* Вертикальные табы */
#main .tabs ul {
	margin: 0;
	padding: 0;
}
#main .tabs img {
    padding: 0;
    border: none;
	width: 100%;
    height: 100%;
    object-fit: cover;
}
.tabs {
	display: flex;
	@media (max-width:767px) {
		display: none;
	}
}
.tabs-link {
	display: flex;
	flex-direction: column;
}
.tabs-container {
	flex-shrink: 0;
	position: relative;
	background: #efefef;
	width: 50%;
	padding: 20px 0 20px 20px;
	float: left;
	z-index: 20;
	box-sizing: border-box;
}
.tabs-container {
	.tab {
		position: relative;
		display: block;
		font-size: 18px;
		color: #fff;
		span {
			color: #0E96D2;
			border-bottom: 1px dashed #0E96D2;
			cursor: pointer;
			user-select: none;
			&:hover {
				font-weight: bold;
				border-bottom: 1px dashed transparent;
			}
		}
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		&.active {
			padding: 20px 0;
			span {
				position: relative;
				font-weight: bold;
				color: #ffffff;
				border-bottom: 1px dashed transparent;
				z-index: 99;
				cursor: default;
			}
			.tab-info {
				position: relative;
				display: block;
				z-index: 99;
				margin-top: 6px;
				color: #fff;
				p {
					color: #fff;
				}
			}
			&::after {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -30px;
				right: 0;
				content: '';
				display: block;
				background-color: #10A3E4;
				border-radius: 10px 0 0 10px;
			}
		}
		.tab-info {
			display: none;
		}
	}
	label {
		position: relative;
	}
	.tab-pseudo.active {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;

		&:before,
		&:after {
		  width: 20px;
		  height: 50%;
		  position: absolute;
		  left: 100%;
		  content: "";
		}

		&:before {
		  top: 1px;
		  background: linear-gradient(to right top, #10A3E4 50%, transparent 53%)
		}

		&:after {
		  bottom: 1px;
		  background: linear-gradient(to right bottom, #10A3E4 50%, transparent 53%)
		}

	}
}
.tab-more {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
	margin-top: 15px;
	font-size: 14px !important;
	color: #0E96D2 !important;
	background-color: #fff;
	border-radius: 10px;
	text-decoration: none !important;
	user-select: none;
	&:hover {
		font-weight: bold;
	}
}
.tab-content{
	position: relative;
	width: calc(100% - 120px);
	float: left;
	box-sizing: border-box;
	z-index: 19;
	display: none;
}

.tab-content:after{
	content: "";
	clear: both;

}
input[name="tab"]{
	display: none;
 }
input[name="tab"]:checked + .tab-content{
	display: block;
	animation: slide 0.5s forwards;
}
@keyframes slide{
	from{
		display: none;
		opacity: 0;
	}
	to{
		display: block;
		opacity: 1;
	}
}

.map-wrapper {
	margin-bottom: 30px;
	@media (max-width:767px) {
		margin-bottom: 10px;
	}
	@media (max-width:490px) {
		padding-top: 274px;
	}
	#map_container {
		@media (max-width:490px) {
			position: absolute;
			top: 0;
			width: 421px !important;
    		height: 391px !important;
			zoom: 0.7;
		}
	}
}

// products
.products {
	margin-bottom: 45px;
	@media (max-width:979px) {
		margin-bottom: 40px;
	}
	@media (max-width:767px) {
		margin-bottom: 30px;
	}
	h3 {
		@media (max-width:979px) {
            margin-bottom: 14px !important;
        }
	}
}
.product-category-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 30px;
	@media (max-width:979px) {
		margin-top: 25px;
	}
	@media (max-width:767px) {
		margin-top: 33px;
	}
}
.product-category {
	margin-bottom: 30px;
	@media (max-width:979px) {
		margin-bottom: 15px;
	}
	&:nth-child(1) {
		order: 2;
		margin-right: 15px;
		margin-bottom: 0;
		@media (max-width:510px) {
			margin: 0 auto;
		}
	}
	&:nth-child(2) {
		order: 3;
		margin-bottom: 0;
		@media (max-width:510px) {
			margin: 0 auto;
		}
	}
	&:nth-child(3) {
		order: 1;
		.product-items {
			@media (max-width:510px) {
				margin: 0 auto;
			}
		}
		.product-item {
			margin-bottom: 10px;
		}
	}
}
.product-category-info {
	.product-category-info-title {
		margin-bottom: 15px;
		font-size: 20px;
		@media (max-width:979px) {
            margin-bottom: 6px;
        }
		@media (max-width:767px) {
			margin-bottom: 10px;
		}
	}
	.product-category-info-desc {
		margin-bottom: 15px;
		font-size: 16px;
		@media (max-width:767px) {
			margin-bottom: 10px;
		}
	}
}
.product-items {
	display: flex;
	flex-wrap: wrap;
	@media (max-width:510px) {
		width: 290px;
		gap: 0 10px;
	}
}
.product-item {
	width: 150px;
	box-sizing: border-box;
	@media (max-width:510px) {
		width: 140px;
	}
	&:not(:last-child) {
		margin-right: 15px;
		@media (max-width:510px) {
			margin-right: 0;
		}
	}
}
.product-item-img{
	width: 150px;
	height: 170px;
	margin-bottom: 10px;
	box-sizing: border-box;
	@media (max-width:510px) {
		width: 140px;
	}
	img {
		padding: 0 !important;
		border: none !important;
		width: 100%;
		height: 100%;
		object-fit: cover;
		@media (max-width:510px) {
			object-fit: contain;
		}
	}
}
.product-item-info {
	div {
		text-align: center;
	}
	a {
		font-size: 16px;
		color: #0E96D2;
		&:hover {
			font-weight: bold;
		}
	}
}

// equipment
.equipment {
	margin-bottom: 50px;
	@media (max-width:979px) {
		margin-bottom: 40px;
	}
	.equipment-slider-items {
		display: none;
		@media (max-width:767px) {
			display: block;
			margin: 0 -15px;
			padding: 25px 15px;
			overflow: hidden;
			background-color: #efefef;
		}
		.equipment-slider-title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 5px;
			@media (max-width:767px) {
				margin-bottom: 0;
			}
		}
		.equipment-slider-desc {
			font-size: 16px;
			@media (max-width:767px) {
				line-height: 1.3;
			}
		}
		.equipment-slider-link {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 30px;
			margin: 5px 0 15px;
			border-radius: 10px;
			background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
			a {
				text-decoration: none;
				color: #fff;
				font-size: 14px;
			}
		}
		.equipment-slider-img {
			img {
				padding: 0 !important;
				border: none !important;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.swiper-pagination {
			.swiper-pagination-bullet.swiper-pagination-bullet-active {
				width: 20px;
				border-radius: 5px;
				background: #0E96D2;
			}
		}
	}
}

// service
.service {
	// margin-bottom: 50px;
	@media (max-width:979px) {
		margin-bottom: 40px;
	}
	h3 {
		font-size: 22px !important;
		margin-bottom: 23px !important;
		@media (max-width:767px) {
			margin-bottom: 21px !important;
		}
	}
}
.service-items {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 30px;
	margin-bottom: 50px;
	padding: 0 30px;
	@media (max-width:979px) {
		margin-bottom: 35px;
		padding: 0 20px;
	}
	@media (max-width:767px) {
		margin-bottom: 20px;
		justify-content: center;
		gap: 0 15px;
	}
	@media (max-width:375px) {
		justify-content: center;
		gap: 0 30px;
		width: 260px;
		margin: 0 auto;
		margin-bottom: 20px;
		margin-top: 15px;
	}
	.service-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width:767px) {
			margin-bottom: 15px;
		}
		img {
			border: none !important;
			padding: 0 !important;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.service-item-description {
			display: block;
			font-size: 16px !important;
			line-height: 19px !important;
			text-align: center;
			color: #3D3D3D !important;
			text-decoration: none !important;
			@media (max-width:979px) {
				flex-direction: column;
			}
		}
		a {
			margin-bottom: 17px;
			@media (max-width:979px) {
				margin-bottom: 13px;
			}
			@media (max-width:767px) {
				margin-bottom: 16px;
			}
		}
	}
}
.service-images {
	max-width: 980px;
	height: 236px;
	@media (max-width:979px) {
		height: 173px;
	}
	@media (max-width:510px) {
		height: 150px;
	}
	.swiper-slide {
		img {
			padding: 0 !important;
			border: none !important;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.service-introtext {
	p {
		&:not(:last-child) {
			margin-bottom: 23px !important;
			@media (max-width:979px) {
				margin-bottom: 19px;
			}
			@media (max-width:767px) {
				margin-bottom: 20px !important;
			}
		}
	}
}

// top-banner
.top-banner {
	position: relative;
	display: none;
	padding: 6px 35px 6px 15px;
	background-color: #efefef;
	border-radius: 10px;
	box-sizing: border-box;
	color: #3D3D3D;
	@media (max-width:979px) {
		margin: 0 20px;
	}
	@media (max-width:767px) {
		padding: 10px 25px 10px 13px;
		margin: 20px 15px 0;
	}

	&-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width:767px) {
			align-items: flex-start;
			flex-direction: column;
		}
	}
	&-close {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		width: 10px;
		height: 10px;
		background: url('/assets/images/banner-close.png') no-repeat center;
		cursor: pointer;
		&:hover {
			background: url('/assets/images/banner-close-blue.png') no-repeat center;
		}
		@media (max-width:767px) {
			top: 15px;
			transform: unset;
		}
	}
	&-more {
		flex-shrink: 0;
		width: 91px;
		height: 24px;
		margin-left: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px !important;
		font-weight: bold;
		color: #fff !important;
		text-decoration: none !important;
		text-transform: uppercase;
		border-radius: 10px;
		background: linear-gradient(180deg, #0EA8E5 0%, #0E96D2 100%);
		line-height: 1;
		&:hover {
			background: linear-gradient(0deg, #0EA7E4 0%, #0EA7E4 100%);
		}
		@media (max-width:979px) {
            line-height: 1 !important;
        }
		@media (max-width:767px) {
			margin-left: 0;
		}
	}
	&-text {
		font-size: 14px;
		margin: 6px 0;
		line-height: 1;
		@media (max-width:979px) {
            line-height: 1 !important;
        }
		@media (max-width:767px) {
			margin: 0 0 7px 0;
		}
		b {
			letter-spacing: 0.5px;
		}
	}
}
.bottom-banner {
	order: 4;
	margin-top: 30px;
	background: linear-gradient(180deg,#0ea8e5 0,#0e96d2 100%);

	.top-banner-more {
		background: #ffb800;
	}

	.top-banner-text {
		color: #ffffff;
	}
}

// contacts-mobile
.contacts-mobile {
	p {
		margin: 0 !important;
	}
	display: none;
	@media (max-width:767px) {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
	&-phones {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		.phone {
			&:first-child {
				// margin-right: 15px;
			}
			p {
				color: #838282;
				@media (max-width:767px) {
					font-size: 14px;
					line-height: 1.2;
				}
			}
			a {
				font-size: 16px;
				font-weight: bold;
				color: #3D3D3D !important;
				text-decoration: none !important;
				letter-spacing: 0.9px;
			}
		}
	}
	&-charge {
		display: flex;
		width: 271px;
		padding: 10px 8px;
		border-radius: 10px;
		background-color: #f4f4f4;
		@media (max-width:767px) {
			padding: 8px;
		}
		&::before {
			content: '';
			display: block;
			width: 14px;
			height: 18px;
			margin-right: 5px;
			background-image: url("/assets/images/charge-point.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		a {
			color: #0F9EDA;
			text-decoration: underline;
			white-space: nowrap;
		}
	}
}